"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "500px"
    },
    attrs: {
      filterable: "",
      multiple: "",
      placeholder: "渠道号",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.channel,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "channel", $$v);
      },
      expression: "listQuery.channel"
    }
  }, _vm._l(_vm.channelList, function (item) {
    return _c("el-option", {
      key: item.channel,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1), _vm._v(" "), _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetimerange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.$t("table.search")))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_count",
      label: "新增用户数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_recharge_amount",
      label: "实充金额"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "actual_settlement_amount",
      label: "实结金额"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_rechange_vip",
      label: "vip实结金额"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_rechange_gold",
      label: "金币实结金额"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "recharge_all_user_count",
      label: "充值总人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "recharge_gold_user_count",
      label: "充值金币人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "recharge_vip_user_count",
      label: "充值VIP人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_count",
      label: "新用户付费人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_amount",
      label: "新用户实充"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_launch_count",
      label: "新用户金币实充"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_settle_amount",
      label: "新用户实结"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_receive_count",
      label: "新用户金币实结"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;