"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("p", {
    on: {
      click: function click($event) {
        _vm.pwdBox = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("form.editPassword")))]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.$t("form.editLoginPassword"),
      visible: _vm.pwdBox,
      width: "400px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.pwdBox = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.formRules,
      model: _vm.form,
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("form.oldPassword"),
      prop: "oldPwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "".concat(_vm.$t("form.pleaseEnter")).concat(_vm.$t("form.oldPassword"))
    },
    model: {
      value: _vm.form.oldPwd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "oldPwd", $$v);
      },
      expression: "form.oldPwd"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("form.newPassword"),
      prop: "newPwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "".concat(_vm.$t("form.pleaseEnter")).concat(_vm.$t("form.newPassword"))
    },
    model: {
      value: _vm.form.newPwd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "newPwd", $$v);
      },
      expression: "form.newPwd"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("form.confirmPassword"),
      prop: "confirmPwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "".concat(_vm.$t("form.pleaseEnter")).concat(_vm.$t("form.confirmPassword"))
    },
    model: {
      value: _vm.confirmPwd,
      callback: function callback($$v) {
        _vm.confirmPwd = $$v;
      },
      expression: "confirmPwd"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.$t("form.confirm")))])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;