"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  route: {
    preface: '序言',
    dashboard: '首页',
    anchorReal: '主播实时',
    userReal: '用户实时',
    statistics: '数据统计',
    coreReport: '核心报表',
    anchorRight: '主播特权报表',
    statisticsBasicDaily: '基本日报',
    statisticsReport: '回报数据',
    statisticsProfit: '应用收益',
    statisticsRecharge: '充值通话明细',
    statisticsUser: '用户注册明细',
    statisticsAnchor: '国家主播日报',
    statisticsChannel: '渠道汇总',
    statisticsChannelBj: '北京时区',
    statisticsCalled: '通话统计',
    statisticsAnchorAnalysis: '主播数据分析',
    statisticsChannelTime: '实时渠道数据',
    statisticsChannelReal: '渠道实时',
    statisticsDot: '打点统计',
    statisticsSecond: '二级渠道',
    statisticsBrace: '数据支撑',
    statisticsVipData: 'VIP数据A/B',
    statisticsAnchorNum: '主播运营数据',
    statisticsSql: 'SQL支撑',
    statisticsUserBehaviorAnalysis: '用户行为分析',
    statisticsRefund: '退款统计',
    basic: '基本管理',
    basicReport: '举报管理',
    basicCooper: '合作意向',
    feedback: '用户反馈',
    manual: '后台管理手册',
    broker: '公会管理',
    brokerSettlement: '公会结算',
    brokerCommissionConfig: '公会提成配置',
    brokerIncome: '主播收入明细',
    user: '用户管理',
    userList: '用户列表',
    userReview: '资料审核',
    userDetail: '用户详情',
    userExtend: '扩展信息',
    userDynamic: '动态管理',
    anchor: '主播管理',
    anchorIndex: '平台主播',
    reviewAnchor: '审核主播',
    anchorReport: '主播报表',
    anchorMission: '记录扩展',
    anchorRecommend: '推荐主播',
    anchorSayHello: '打招呼审核',
    bPhoto: 'B面照片',
    userPhone: '相册审核',
    anchorRank: '主播日/周榜',
    operation: '运营管理',
    operationProduct: '产品管理',
    operationBrand: '应用管理',
    operationVersion: '版本管理',
    operationAudit: '发起审核',
    operationPaychannel: '支付通道',
    operationAdvcost: '广告投放',
    operationCountry: '地区配置',
    operationChannel: '渠道账号',
    operatingSystem: '币商管理',
    operatingDealer: '币商列表',
    operationGift: '会场礼物',
    operationPromoter: '推广人',
    operationSayhello: '打招呼',
    operationPhoto: '默认头像',
    operationRobotDetail: '话术机器人详情',
    operationFanchorVideo: '系统全屏拨打',
    room: '房间管理',
    operationLink: '环节管理',
    operationQuestion: '问题管理',
    chat: '聊天管理',
    chatVideo: '视频通话',
    chatText: '文字聊天',
    chatVoice: '语音聊天',
    chatNews: '消息中台',
    chatReply: '客服配置',
    system: '权限管理',
    systemMenu: '菜单管理',
    systemRole: '角色管理',
    systemLogs: '操作日志',
    systemWarnLogs: '告警日志',
    systemSlowSqlLogs: '慢sql日志',
    systemAccount: '权限管理',
    finance: '财务管理',
    financeUserRecharge: '充值流水',
    autoSub: '自动续订',
    financeSpending: '用户消费',
    financeGifttrade: '礼物交易',
    financeDlocalPayout: 'Dlocal Payout',
    financeGear: '提现档位',
    financeSettlement: '公会结算',
    financeMultilevelSettlement: '分级公会结算',
    financeRecharge: '人工充值',
    financeWithdrawal: '国内提现',
    financeAnchorWithdrawal: '主播提现',
    financeUnusual: '待支付订单',
    financeInfo: '内购日志',
    financeRefund: '退款订单',
    brokerManage: '我的公会',
    brokerManageIndex: '身份初审',
    brokerManageAnchor: '主播资料',
    brokerManageIncome: '主播明细',
    channelManageData: '实时数据',
    channelHistory: '历史数据',
    distributorsReport: '分销日报',
    channelManage: '应用数据',
    channelCalled: '通话记录',
    channelReport: 'ROI数据',
    channelCnManage: '渠道数据',
    channelCnManageDate: '渠道数据',
    // channelProfit: '应用收益',
    configCenter: '配置中心',
    configCenterAdjust: 'Adjust参数配置',
    configCenterHuaweiPay: '华为支付配置',
    systemConfig: '系统配置',
    systemParameterConfig: '参数配置',
    systemConfigUserInfo: '系统用户',
    systemConfigPayment: '第三方支付',
    systemConfigAbTest: 'Server端诱导配置',
    systemConfigMerchant: '商户号配置',
    systemConfigInduce: '诱导策略',
    systemConfigMaterial: '素材上传',
    systemConfigTestDevice: '测试设备',
    examine: '发包管理',
    releaseProcess: '发包流程',
    examineData: '审核数据',
    examineUser: '审核账号',
    examineVideo: '对应视频',
    examineDynamic: '对应文字动态',
    examineImage: '对应图片动态',
    examineRank: '排行榜',
    brokerCnManage: '公会后台',
    brokerCnDate: '数据概要',
    brokerLivedata: '直播数据',
    brokerCash: '提现详情',
    biAdministration: 'BI管理',
    GoogleAccount: 'Google广告',
    biAccount: 'Adjust配置',
    dataMiddle: '数据中台',
    advertPlatform: '广告平台',
    sysdictData: '参数详情',
    userPhotoAlbum: '用户相册',
    signReport: '签到统计',
    mqRecord: 'MQ失败记录',
    pushFlow: '推流监控',
    forcedUpdate: 'Nacos配置',
    anchorDaily: '主播收入',
    matchRecord: '匹配记录',
    induceDetail: '诱导文案详情',
    luckReport: '大转盘数据报表',
    payWithdrawal: '支付打款',
    dynamicList: '动态列表',
    productAnalysis: '产品分析数据',
    searchOrder: '订单查询',
    anchorReportCore: '主播数据',
    anchorCoreReport: '主播核心报表',
    dayRevenueReport: '主播收入分布',
    realCharts: '实时图表',
    goodsReport: '礼物日报',
    userReportCore: '用户数据',
    activeUserRecharge: '日活充值用户分析',
    retainedUserReport: '用户留存日报',
    realBrandCharts: '应用图表',
    emailTransfer: '邮件召回',
    freeSubOrder: '免费使用订单',
    operationDeploy: '运营配置',
    wordsDeploy: '文案配置',
    anchorExtension: '主播扩展',
    countryData: '国家数据',
    AiSystem: 'AI管理',
    AiWordsLibrary: 'AI内容',
    AiAnchorPhoto: 'AI主播相册',
    rechargePools: '线下转账'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小',
    lock: '锁屏',
    setLockPassword: '设置锁屏密码',
    bindGoogleAuth: '绑定谷歌认证'
  },
  login: {
    title: '视频项目管理后台',
    logIn: '登录',
    username: '账号',
    password: '密码',
    googleCode: '谷歌验证码',
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
  },
  table: {
    search: '搜索',
    edit: '编辑',
    add: '新增',
    delete: '删除',
    detail: '详情',
    synchro: 'IM同步',
    batchHandle: '批量处理',
    batchReview: '批量审核',
    selectAtLeastOne: '请至少选择一项',
    operating: '操作',
    userName: '用户名',
    password: '密码',
    status: '状态',
    lastLogin: '最后一次登录',
    free_video_time: '剩余免费次数',
    free_video_times_vip: '剩余限时免费次数',
    free_expired_at: '失效时间',
    updateTime: '修改时间',
    createdTime: '创建时间',
    creator: '创建者',
    sendUserId: '发送人ID',
    receiveUserId: '接收人ID',
    content: '内容',
    date: '日期',
    time: '时间',
    model: '机型',
    sequence: '序列',
    mark: '标识',
    brandName: '应用名',
    remark: '备注',
    operator: '操作人',
    number: '编号',
    anchor: '主播',
    user: '用户',
    balance: '余额',
    material: '素材',
    avatar: '头像',
    nickName: '昵称',
    registration: '注册时间',
    userId: '用户ID',
    brokerName: '工会名称',
    countryCode: '国家编码',
    totalCalls: '通话总次数',
    numberCalls30s: '30S内通话数',
    numberActiveCalls: '主动拨打次数',
    numberCallsTheHost: '主播拨打接通次数',
    numberCallsCalled: '被叫接通次数',
    unlockRevenueGold: '解锁照片视频收入',
    anchorId: '主播ID',
    Num: '编号',
    photo: '照片',
    broker: '公会',
    operation: '操作',
    adopt: '通过',
    refuse: '拒绝',
    refuseReasons: '拒绝理由',
    noticeContent: '通知文案',
    custom: '自定义',
    customReasons: '自定义理由',
    userSource: '用户来源',
    sex: '性别',
    mobile: '手机号',
    city: '城市',
    brand: '应用',
    onlineStatus: '在线状态',
    name: '名称',
    type: '类型',
    belongs: '使用应用',
    currency: '币种',
    standardPrice: '内购售价',
    goldPrice: '第三方支付售价',
    giveCoin: '赠送金币',
    freeVIPDays: '第三方支付赠送会员天数',
    image: '图片',
    video: '视频',
    href: '外链',
    version: '版本',
    style: '样式',
    show: '展示',
    identity: '身份',
    orderId: '订单号',
    price: '价格',
    signature: '签名',
    basicInfo: '基本信息',
    rechargeRecord: '充值记录',
    expensesRecord: '消费记录',
    channel: '渠道',
    amount: '金额',
    invitationCode: '邀请码',
    unionShare: '工会分成比例',
    brokerModel: '公会模式',
    loginID: '登录ID',
    artists: '旗下主播',
    isEditaArtistshare: '是否可修改主播分成比例',
    selectTimePeriod: '所选时间段',
    settingPermissions: '设置权限',
    modifyTheScale: '修改比例',
    settingToThePublic: '设置对公',
    addingParameters: '添加参数',
    allAnchors: '所有主播',
    recommended: '推荐主播',
    notRecommend: '未推荐主播',
    country: '国家',
    edition: '版本',
    videoCall: '视频通话',
    speechcraftList: '话术列表',
    startCallTime: '开始通话时间',
    talkTime: '通话时长/min',
    platformOrder: '平台订单',
    editBroker: '编辑',
    addBroker: '新增',
    startTime: '开始时间',
    endTime: '结束时间',
    firstFrame: '首帧图',
    img: '图片/视频',
    isExamine: '是否提审数据',
    isVip: '是否VIP',
    expiredAt: '到期时间',
    versions: '当前版本',
    app_version: '注册版本',
    language: '当前手机语言',
    cancelAnchor: '取消主播身份',
    deviceStatus: '设备状态',
    review: '审核',
    reviewAvatar: '审核头像',
    reviewNick: '审核昵称',
    reviewSignature: '审核签名',
    reviewVideo: '审核视频秀',
    loginType: '登录方式',
    anchorCount: '旗下主播数',
    brokerIncome: '收入明细',
    reviewPost: '审核动态',
    managePost: '动态管理',
    postComment: '动态评论',
    commentShow: '视频秀评论',
    caches: '图片/首帧图',
    belongMoment: '所属动态',
    belongVideo: '所属视频秀',
    belongRegion: '公会所属国家编码',
    commentCount: '评论总数',
    likeCount: '喜欢总数',
    phoneVersions: '手机版本号',
    deviceType: '设备类型',
    isLikeVip: '是否喜欢VIP',
    isLikeDeadline: '喜欢VIP到期',
    region: '地区',
    traceability: '用户来源',
    randomShow: '随机秀',
    callShow: '来电秀',
    reviewMoment: '动态审核',
    editBasic: '更改基本资料',
    deviceId: '设备ID',
    brokerId: '公会ID',
    videoTime: '视频时长',
    totalIncome: '总收入',
    videoChatIncome: '视频聊天收入',
    messageCoins: '私聊收入',
    videoCalls: '视频收入',
    taskCoins: '任务收入',
    incomingCalls: '被叫总次数',
    connectedIncomingCalls: '接通视频次数',
    callConnectRate: '被叫接通率',
    textIncome: '文字收入',
    giftsIncome: '礼物收入',
    unlockPhotos: '解锁照片',
    privateVideosWatched: '私密视频',
    voiceTime: '语音时长',
    numberOfCalls: '通话次数',
    connection: '接通数',
    anchor_rating: '主播评分',
    startFrom: '开始时间',
    endAt: '结束时间',
    totalHosts: '旗下主播数',
    hostCompleteTarget: '达标主播数',
    totalCoins: '金币收入',
    girlSalary: '主播工资',
    agencyCommission: '公会工资',
    payChannel: '支付渠道',
    paymentOption: '子通道',
    clientType: '机型',
    productType: '商品类型',
    productId: '商品ID',
    birthday: '生日',
    area: '地区',
    fakeVideo: '假视频',
    paymentOption1: '银行编号',
    orderStatus: '订单状态',
    all: '全部',
    successfulNormal: '成功(正常)',
    successfulTest: '成功(测试单)',
    successfulRefund: '成功(退款)',
    export: '导出',
    transactionNumber: '银行单号',
    paymentTime: '创建时间(零时区)',
    creditTime: '入账时间(零时区)',
    details: '详情',
    orderDetails: '订单详情',
    system: '应用类型',
    transactionNumber1: '支付平台单号',
    coins: '金币数量',
    vipType: '会员类型',
    appChannel: '渠道编号',
    currencyType: '币种类型',
    exposure: '曝光次数',
    unknown: '未知',
    price1: '支付金额',
    extraVipDays: '赠送vip天数',
    googlePay: 'Google支付',
    applePay: '苹果支付',
    coinsRecharge: '充值💰',
    vipTime: 'VIP充值',
    subscriptionVip: '订阅VIP',
    diamond: '钻石💎',
    check: '查看',
    platformOrderInfo: '平台侧信息',
    reviewPhone: '相册审核',
    agencyBonus: '工会奖励',
    match: '匹配卡',
    anchorLevel: '颜值等级',
    firstExamina: '身份初审',
    reviewExamina: '复审未通过',
    commentsTeam: '拒绝理由',
    lastCallAt: '最后通话时间',
    moments: '动态数量',
    album: '相册数量',
    generalDiamonds: '所有主播赚取钻石数',
    estimateEarning: '公会整体收入美金',
    agentBenefit: '公会长收入美金',
    salariedGirls: '达标主播人数',
    totalAnchors: '主播总人数',
    activeAnchors: '主播通话人数',
    history: '历史',
    orderSource: '订单来源'
  },
  tips: {
    tips: '提示',
    sure: '确认',
    cancel: '取消',
    handleAnchor: '确定要处理该用户吗？',
    passAnchor: '确定要通过该用户的审核吗？',
    refuseAnchor: '确定要拒绝该用户的审核吗？',
    result: '处理成功',
    userNotexist: '用户不存在'
  },
  form: {
    pleaseEnter: '请输入',
    pleaseChoose: '请选择',
    save: '保存',
    confirm: '确 定',
    cancel: '取 消',
    editSuccess: '修改成功',
    addSuccess: '添加成功',
    handleSuccess: '成功！辛苦了，喝杯咖啡吧',
    submitSuccess: '提交成功',
    lockPassword: '锁屏密码',
    lockPasswordNotEmpty: '锁屏密码不能为空',
    editPassword: '修改密码',
    editLoginPassword: '修改登录密码',
    oldPassword: '旧密码',
    oldPasswordNotEmpty: '旧密码不能为空',
    newPassword: '新密码',
    newPasswordNotEmpty: '新密码不能为空',
    confirmPassword: '确认密码',
    confirmPasswordNotEmpty: '确认密码不能为空',
    passwordEnterError: '密码输入不一致',
    mark: '标识',
    brandName: '应用名',
    remark: '备注',
    userName: '用户名',
    handle: '处理',
    status: '状态',
    editUserInfo: '修改用户资料',
    editUserInfoTips: '此操作将修改用户信息, 是否继续?',
    deleteBrokerInfo: '此操作将移除公会，取消旗下所有主播身份，并清理主播钱包！',
    loginPwd: '登录密码',
    googleCode: '谷歌验证码',
    confirmSubmit: '确认提交',
    startDate: '开始日期',
    endDate: '结束日期',
    deleteContent: '确认要删除选中内容么？'
  },
  dialog: {
    editBrand: '编辑应用',
    addBrand: '新增应用',
    editAccount: '编辑账户',
    addAccount: '新增账户',
    reportReview: '举报审核',
    addBroker: '增加经纪人',
    addMenu: '添加菜单',
    editMenu: '编辑菜单',
    addRole: '添加角色',
    editRole: '编辑角色'
  },
  filters: {
    haveRead: '已读',
    unread: '未读',
    handle: '处理',
    unhandle: '未处理',
    normal: '正常',
    offline: '下线',
    notActivated: '未启用',
    user: '用户',
    anchor: '主播',
    robot: '机器人',
    man: '男',
    girl: '女',
    jimonthVip: '季会员',
    nianmonthVip: '年会员',
    yuemonthVip: '月会员',
    daysVip: '周会员'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  dashboard: {
    totalPaid: '付费总人数',
    totalAmountOfUsers: '用户实充总额',
    settledVIPAmount: '实结VIP金额',
    actualSettlementAmountOfPlatform: '平台实结额'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  googleAuth: {
    step1: '下载并安装谷歌身份验证器',
    step2: '使用谷歌身份验证器扫描二维码或手动输入密钥',
    step3: '完成绑定',
    copyCode: '复制二维码',
    tip: '完成绑定之前，请妥善备份您的密钥。如果你的手机丢失、被盗或密钥被删除，你将需要此密钥找回你的谷歌二次验证。'
  },
  customer: {
    title: '客服-融云',
    titleNim: '客服-网易',
    pleaseInputUserId: '请输入用户ID',
    quickReply: '快捷回复',
    pleaseInputMassage: '请输入消息...',
    send: '发送',
    rongBtnTip: '融云客服(点击后登录客服账号)',
    nimBtnTip: '网易客服(点击后登录客服账号)'
  }
};