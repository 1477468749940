"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actionList = actionList;
exports.activeUsersAndAnchorList = activeUsersAndAnchorList;
exports.anchorDailyList = anchorDailyList;
exports.braceList = braceList;
exports.channelAll = channelAll;
exports.channelList = channelList;
exports.channelReal = channelReal;
exports.channelTime = channelTime;
exports.coreReportList = coreReportList;
exports.delSql = delSql;
exports.editCoreReport = editCoreReport;
exports.editReportMark = editReportMark;
exports.editSql = editSql;
exports.getConnectedDurationByCountry = getConnectedDurationByCountry;
exports.getFeaturesDataList = getFeaturesDataList;
exports.getLuckChatList = getLuckChatList;
exports.getLuckList = getLuckList;
exports.getReportMarkList = getReportMarkList;
exports.getServiceConnectedNum = getServiceConnectedNum;
exports.getUriVisitedList = getUriVisitedList;
exports.goodsReportList = goodsReportList;
exports.list = list;
exports.makeList = makeList;
exports.passagewayList = passagewayList;
exports.paymentList = paymentList;
exports.profitList = profitList;
exports.rechargeList = rechargeList;
exports.refundList = refundList;
exports.regionList = regionList;
exports.secondList = secondList;
exports.showFirstWithdrawal = showFirstWithdrawal;
exports.signRecordList = signRecordList;
exports.signReportList = signReportList;
exports.sqlList = sqlList;
exports.versionList = versionList;
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
/**
 * 获取报表标注信息
 * @param {*} params
 */
function getReportMarkList(params) {
  return (0, _request.default)({
    url: '/statistics/getReportMarkList',
    method: 'get',
    params: params
  });
}
/**
 * 编辑报表标注信息
 * @param {*} data
 */
function editReportMark(data) {
  return (0, _request.default)({
    url: '/statistics/editReportMark',
    method: 'post',
    data: data
  });
}
/**
 * 数据总览列表
 * @param {*} params
 */
function list(params) {
  return (0, _request.default)({
    url: '/statistics/list',
    method: 'get',
    params: params
  });
}
/**
 * 获取主播用户在线人数及国家编码列表
 * @param {*} params
 */
function activeUsersAndAnchorList(params) {
  return (0, _request.default)({
    url: '/statistics/list/activeUsersAndAnchor',
    method: 'get',
    params: params
  });
}

/**
 * 应用收益
 * @param {*} params
 */
function profitList(params) {
  return (0, _request.default)({
    url: '/statistics/list/profit',
    method: 'get',
    params: params
  });
}

/**
 * 版本数据
 * @param {*} params
 */
function braceList(params) {
  return (0, _request.default)({
    url: '/statistics/list/brace',
    method: 'get',
    params: params
  });
}

/**
 * 版本号查询
 * @param {*} params
 */
function versionList(params) {
  return (0, _request.default)({
    url: '/statistics/list/version',
    method: 'get',
    params: params
  });
}

/**
 * 支付通道数据
 * @param {*} params
 */
function paymentList(params) {
  return (0, _request.default)({
    url: '/statistics/list/payment',
    method: 'get',
    params: params
  });
}

/**
 * 通道数据详情
 * @param {*} params
 */
function passagewayList(params) {
  return (0, _request.default)({
    url: '/statistics/list/passageway',
    method: 'get',
    params: params
  });
}

/**
 * 地区数据报表
 * @param {*} params
 */
function regionList(params) {
  return (0, _request.default)({
    url: '/statistics/list/region',
    method: 'get',
    params: params
  });
}

/**
 * 应用数据报表
 * @param {*} params
 */
function makeList(params) {
  return (0, _request.default)({
    url: '/statistics/list/make',
    method: 'get',
    params: params
  });
}

/**
 * 新用户行为
 * @param {*} params
 */
function actionList(params) {
  return (0, _request.default)({
    url: '/statistics/list/action',
    method: 'get',
    params: params
  });
}

/**
 * 退款统计
 * @param {*} params
 */
function refundList(params) {
  return (0, _request.default)({
    url: '/statistics/list/refund',
    method: 'get',
    params: params
  });
}

/**
 * sql支撑
 * @param {*} params
 */
function sqlList(params) {
  return (0, _request.default)({
    url: '/statistics/list/sql',
    method: 'get',
    params: params
  });
}

/**
 * sql支撑-编辑
 */
function editSql(data) {
  return (0, _request.default)({
    url: '/statistics/edit/sql',
    method: 'POST',
    data: data
  });
}

/**
 * sql支撑-编辑
 */
function delSql(data) {
  return (0, _request.default)({
    url: '/statistics/delete/sql',
    method: 'POST',
    data: data
  });
}

/**
 * 服务端统计 - 获取当天访问用户数/访问次数/当前在线用户总数/当前在线主播数/当前在线男用户数
 * @param {*} data
 */
function getServiceConnectedNum(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/service/static/getServiceConnectedNum"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 服务端统计 - 获取24小时内接口访问记录
 * @param {*} data
 */
function getUriVisitedList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/service/static/getUriVisitedList"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 服务端统计 - 按国家获取24小时内连接到服务器的次数及平均时长
 * @param {*} data
 */
function getConnectedDurationByCountry(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/service/static/getConnectedDurationByCountry"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 充值接通明细
 * @param {*} params
 */
function rechargeList(params) {
  return (0, _request.default)({
    url: '/statistics/list/recharge',
    method: 'get',
    params: params
  });
}

/**
 * 渠道汇总
 * @param {*} params
 */
function channelList(params) {
  return (0, _request.default)({
    url: '/statistics/list/channel',
    method: 'get',
    params: params
  });
}

/**
 * 所有渠道
 * @param {*} params
 */
function channelAll(params) {
  return (0, _request.default)({
    url: '/statistics/list/channelAll',
    method: 'get',
    params: params
  });
}

/**
 * 渠道实时汇总
 * @param {*} params
 */
function channelTime(params) {
  return (0, _request.default)({
    url: '/statistics/list/channelTime',
    method: 'get',
    params: params
  });
}

/**
 * 渠道实时
 * @param {*} params
 */
function channelReal(params) {
  return (0, _request.default)({
    url: '/statistics/list/channelReal',
    method: 'get',
    params: params
  });
}

/**
 * 二级渠道
 * @param {*} params
 */
function secondList(params) {
  return (0, _request.default)({
    url: '/statistics/list/second',
    method: 'get',
    params: params
  });
}

/**
 * 核心报表
 */
function coreReportList(params) {
  return (0, _request.default)({
    url: '/statistics/list/coreReport',
    method: 'get',
    params: params
  });
}

/**
 * 设置核心报表 主播工会结算
 */
function editCoreReport(data) {
  return (0, _request.default)({
    url: '/statistics/editCoreReport',
    method: 'post',
    data: data
  });
}

/**
 * 获取核心报表 首提主播列表
 */
function showFirstWithdrawal(data) {
  return (0, _request.default)({
    url: '/statistics/showFirstWithdrawal',
    method: 'post',
    data: data
  });
}

/**
 * 签到报表
 */
function signReportList(params) {
  return (0, _request.default)({
    url: '/statistics/list/signReport',
    method: 'get',
    params: params
  });
}

/**
 * 签到记录
 */
function signRecordList(params) {
  return (0, _request.default)({
    url: '/statistics/list/signRecord',
    method: 'get',
    params: params
  });
}

/**
 * 主播收入表
 */
function anchorDailyList(params) {
  return (0, _request.default)({
    url: '/statistics/list/anchorDaily',
    method: 'get',
    params: params
  });
}

/**
 * 大转盘抽奖数据报表
 */
function getLuckList(params) {
  return (0, _request.default)({
    url: '/statistics/getLuckList',
    method: 'get',
    params: params
  });
}

/**
 * 大转盘抽奖数据报表
 */
function getLuckChatList(params) {
  return (0, _request.default)({
    url: '/statistics/getLuckChatList',
    method: 'get',
    params: params
  });
}

/**
 * 特征榜数据分析
 */
function getFeaturesDataList(params) {
  return (0, _request.default)({
    url: '/statistics/getFeaturesDataList',
    method: 'get',
    params: params
  });
}

/**
 * 礼物日报
 */
function goodsReportList(params) {
  return (0, _request.default)({
    url: '/statistics/goodsReportList',
    method: 'get',
    params: params
  });
}