"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  route: {
    preface: 'Preface',
    dashboard: 'Dashboard',
    anchorReal: 'AnchorReal',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    anchorSayHello: 'SAY—HELLO',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Back To Top',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    Table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    profile: 'Profile',
    brokerManage: 'Artist management',
    brokerManageIndex: 'Artist review',
    brokerManageIncome: 'Artist income',
    brokerSettlement: 'Salary Report',
    brokerManageAnchor: 'My Artists',
    systemConfigUserInfo: 'System user config',
    systemConfigAbTest: 'A/B Test',
    systemConfigMerchant: 'Merchant Num',
    user: 'User Management',
    userList: 'User List',
    userReview: 'User Review',
    userExtend: 'User Extension',
    userDynamic: 'Moments Manage',
    broker: 'Agency',
    anchor: 'Anchor Management',
    anchorIndex: 'Anchor list',
    reviewAnchor: 'Review Anchor',
    anchorRecommend: 'Anchor promotion',
    anchorRank: 'Anchor Rank',
    biAdministration: 'BiAdministration',
    facebookAccount: 'FacebookAccount',
    AppleAccount: 'AppleAdsAccount',
    biAccount: 'BiAccount',
    anchorExtension: 'Anchor Extension',
    countryData: 'Country Data'
  },
  navbar: {
    dashboard: 'Dashboard',
    github: 'Github',
    logOut: 'Log Out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'Video project management background',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    googleCode: 'Google verification code',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
    dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    userId: 'User ID',
    brokerName: 'Agency name',
    countryCode: 'Country',
    totalCalls: 'Call Numbers',
    numberCalls30s: 'Calls Ended in 30s',
    numberActiveCalls: 'Calling Times',
    numberCallsTheHost: 'Calling&connected',
    numberCallsCalled: 'incoming calls&connected',
    unlockRevenueGold: 'unlock photo&video',
    anchorId: 'Anchor ID',
    date: 'Date',
    time: 'Time',
    video: 'Video',
    Num: 'Num',
    photo: 'Profile Photo',
    broker: 'Agency',
    operation: 'Operation',
    adopt: 'Adopt',
    refuse: 'Refuse',
    refuseReasons: 'Reasons for refusal',
    noticeContent: 'Notice Content',
    custom: 'Custom',
    customReasons: 'Custom reason',
    allAnchors: 'All anchors',
    recommended: 'Promoted',
    notRecommend: 'Not Promoted',
    nickName: 'Name',
    signature: 'Signature',
    registration: 'Registration',
    customer: 'Customer',
    belongs: 'Brand',
    country: 'Country',
    channel: 'Channel',
    edition: 'Edition',
    avatar: 'Avatar',
    sex: 'Gender',
    brand: 'Brand',
    createdTime: 'CreatedTime',
    operating: 'Operation',
    detail: 'Details',
    synchro: 'IM Synchro',
    mobile: 'Mobile',
    basicInfo: 'Essential',
    identity: 'Identity',
    lastLogin: 'Last Login',
    free_video_time: '剩余免费次数',
    free_video_times_vip: '剩余限时免费次数',
    free_expired_at: '失效时间',
    rechargeRecord: 'Recharge record',
    expensesRecord: 'Expenses Record',
    videoCall: 'Video Call',
    orderId: 'Order Id',
    amount: 'Amount',
    number: 'Num',
    startCallTime: 'Start Call Time',
    price: 'Price',
    talkTime: 'Talk time/min',
    platformOrder: 'Payment Platform Order',
    sequence: 'sequence',
    invitationCode: 'Invitation Code',
    name: 'Name',
    city: 'City',
    loginID: 'loginID',
    creator: 'creator',
    isEditaArtistshare: 'Is Edita Artistshare',
    unionShare: 'Union Share',
    brokerModel: 'Broker Model',
    password: 'Password',
    editBroker: 'Edit Broker',
    addBroker: 'Add Broker',
    startTime: 'Start Time',
    endTime: 'End Time',
    firstFrame: 'First Frame',
    img: 'IMG/Video',
    isExamine: 'Is Examine',
    isVip: 'Is Vip',
    expiredAt: 'Deadline',
    versions: 'versions',
    app_version: 'Registered version',
    cancelAnchor: 'Cancel anchor',
    deviceStatus: 'Device Status',
    selectAtLeastOne: 'Please select at least one item',
    review: 'Review',
    reviewAvatar: 'User Profile Photo',
    reviewNick: 'Name',
    reviewSignature: 'Signature',
    reviewVideo: 'Short Video',
    loginType: 'Login Type',
    anchorCount: 'Number of anchors',
    brokerIncome: 'Income details',
    reviewPost: 'Review post',
    managePost: 'Manage post',
    postComment: 'Post comment',
    commentShow: 'Comments for video shows',
    content: 'Contents',
    caches: 'Caches',
    belongMoment: 'Belonging to moment',
    belongVideo: 'Belonging to video show',
    belongRegion: 'Region code',
    commentCount: 'comments',
    likeCount: 'Likes',
    phoneVersions: 'Mobile versions',
    deviceType: 'Device type',
    balance: 'Balance',
    isLikeVip: 'Is like Vip',
    isLikeDeadline: 'is likeVip deadline',
    region: 'Region',
    traceability: 'Traceability',
    randomShow: 'Random show',
    callShow: 'Call show',
    reviewMoment: 'Review moment',
    editBasic: 'Modify data',
    deviceId: 'Device ID',
    brokerId: 'Agency ID',
    videoTime: 'Video Time',
    totalIncome: 'Total income',
    videoChatIncome: 'Video chat income',
    messageCoins: 'Message coins',
    videoCalls: 'Video calls',
    taskCoins: 'Task coins',
    incomingCalls: 'Incoming calls',
    connectedIncomingCalls: 'Connected incoming calls',
    callConnectRate: 'Call connect rate',
    textIncome: 'Text income',
    giftsIncome: 'Gifts income',
    unlockPhotos: 'Unlock photos',
    privateVideosWatched: 'Private videos watched',
    voiceTime: 'Voice Time',
    numberOfCalls: 'Number of calls',
    connection: 'Connection',
    anchor_rating: 'Anchor rating',
    startFrom: 'Start from',
    endAt: 'End at',
    totalHosts: 'Total Hosts',
    hostCompleteTarget: 'Host Complete Target',
    totalCoins: 'Total Coins',
    girlSalary: "Girl's Salary",
    agencyCommission: 'Agency Commission',
    payChannel: 'payChannel',
    clientType: 'clientType',
    productType: 'productType',
    productId: 'Product ID',
    birthday: 'birthday',
    area: 'area',
    fakeVideo: 'Fake video',
    paymentOption: 'Payment Option',
    orderStatus: 'Order Status',
    all: 'All',
    successfulNormal: 'Successful(Normal)',
    successfulTest: 'Successful(Test)',
    successfulRefund: 'successful(Refund)',
    transactionNumber: 'Transaction Number',
    paymentTime: 'Payment Time(OTC+0)',
    creditTime: 'Credit Time(OTC+0)',
    details: 'Details',
    orderDetails: 'Order Details',
    system: 'System',
    transactionNumber1: 'Transaction Number',
    coins: 'Coins',
    vipType: 'VIP Type',
    appChannel: 'App Channel',
    paymentOption1: 'Payment Option',
    currencyType: 'Currency Type',
    exposure: 'Exposure',
    unknown: 'Unknown',
    price1: 'Price',
    extraVipDays: 'Extra Vip Days',
    googlePay: 'Google pay',
    applePay: 'Apple Pay',
    coinsRecharge: 'Coins Recharge',
    vipTime: 'VIP(1 time)',
    subscriptionVip: 'subscription VIP',
    diamond: 'Diamond',
    check: 'Check',
    platformOrderInfo: 'Platform Order Info',
    agencyBonus: 'Agency Bonus',
    match: 'Match',
    anchorLevel: 'Appearance level',
    firstExamina: 'Waiting for review',
    reviewExamina: 'Review Results',
    commentsTeam: 'Comments from Review Team',
    lastCallAt: 'Last Call at',
    moments: 'Moments',
    album: 'Album',
    generalDiamonds: 'General Diamonds',
    estimateEarning: 'Estimate Earning $',
    agentBenefit: 'Agent Benefit $',
    salariedGirls: 'Salaried Girls',
    totalAnchors: 'Total Anchors',
    activeAnchors: 'Active Anchors',
    history: 'History',
    orderSource: 'Order Source'
  },
  filters: {
    haveRead: '已读',
    unread: '未读',
    handle: '处理',
    unhandle: '未处理',
    normal: '正常',
    offline: '下线',
    notActivated: '未启用',
    user: '用户',
    anchor: '主播',
    robot: '机器人',
    man: 'Man',
    girl: 'Female',
    jimonthVip: '3 months VIP',
    nianmonthVip: '12 months VIP',
    yuemonthVip: '1 month VIP',
    daysVip: '7 Days VIP'
  },
  form: {
    save: 'Save',
    pleaseChoose: 'Please select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    status: 'Status',
    handleSuccess: 'Successful',
    editSuccess: 'Modification succeeded',
    addSuccess: 'Successfully added',
    pleaseEnter: 'Please enter',
    editUserInfo: 'Modify user data',
    editUserInfoTips: 'This operation will modify user information. Do you want to continue?',
    deleteBrokerInfo: 'This operation will remove the guild, cancel all the identity of its anchor, and clean the anchor wallet!',
    startDate: 'start Date',
    endDate: 'end Date',
    deleteContent: 'Are you sure you want to delete the selected content?'
  },
  tips: {
    tips: 'Tips',
    sure: 'Sure',
    cancel: 'Cancel',
    handleAnchor: 'Are you sure to pass the audit?',
    passAnchor: 'Are you sure to pass the artist review?',
    refuseAnchor: 'Are you sure you want to reject the artist review?',
    result: 'Processing successful'
  },
  example: {
    warning: 'Creating and editing pages cannot be cached by keep-alive because keep-alive include does not currently support caching based on routes, so it is currently cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or do not use keep-alive include to cache all pages directly. See details'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  dashboard: {
    totalPaid: '付费总人数',
    totalAmountOfUsers: '用户实充总额',
    settledVIPAmount: '实结VIP金额',
    actualSettlementAmountOfPlatform: '平台实结额'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  customer: {
    title: 'Customer service - Rong',
    titleNim: 'Customer service - Nim',
    pleaseInputUserId: 'Please enter user ID',
    quickReply: 'Quick Reply',
    pleaseInputMassage: 'Please enter a message...',
    send: 'Send',
    rongBtnTip: 'Rongyun customer service (click to log in to the customer service account)',
    nimBtnTip: 'Netease customer service (click to log in to the customer service account)'
  }
};