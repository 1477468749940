"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminAccountList = adminAccountList;
exports.delAdmin = delAdmin;
exports.delMqMessage = delMqMessage;
exports.deleteMenu = deleteMenu;
exports.editAdmin = editAdmin;
exports.editAdminPwd = editAdminPwd;
exports.editMenu = editMenu;
exports.editRole = editRole;
exports.editRoleMenu = editRoleMenu;
exports.logsList = logsList;
exports.menuList = menuList;
exports.mqRecordList = mqRecordList;
exports.resetGoogleAuth = resetGoogleAuth;
exports.resetPassword = resetPassword;
exports.roleList = roleList;
exports.sendMqMessage = sendMqMessage;
exports.slowSqlLogsList = slowSqlLogsList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
/**
 * 获取账号列表
 * @param {*} params
 */
function adminAccountList(params) {
  return (0, _request.default)({
    url: '/system/account/admin',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增管理员
 * @param {*} data
 */
function editAdmin(data) {
  return (0, _request.default)({
    url: '/system/admin/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除管理员
 * @param {*} data
 */
function delAdmin(data) {
  return (0, _request.default)({
    url: '/system/admin/delete',
    method: 'post',
    data: data
  });
}

/**
 * 重置谷歌身份验证
 * @param {*} data
 */
function resetGoogleAuth(data) {
  return (0, _request.default)({
    url: '/system/admin/reset/google/auth',
    method: 'post',
    data: data
  });
}

/**
 * 重置密码
 * @param {*} data
 */
function resetPassword(data) {
  return (0, _request.default)({
    url: '/system/admin/reset/password',
    method: 'post',
    data: data
  });
}

/**
 * 修改管理员密码
 * @param {*} data
 */
function editAdminPwd(data) {
  return (0, _request.default)({
    url: '/system/admin/pwd/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取操作日志
 * @param {*} params
 */
function logsList(params) {
  return (0, _request.default)({
    url: '/system/logs/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取慢sql日志
 * @param {*} params
 */
function slowSqlLogsList(params) {
  return (0, _request.default)({
    url: '/system/slowSqlLogsList',
    method: 'get',
    params: params
  });
}

/**
 * 获取菜单列表
 * @param {*} params
 */
function menuList(params) {
  return (0, _request.default)({
    url: '/system/menu/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑菜单
 * @param {*} data
 */
function editMenu(data) {
  return (0, _request.default)({
    url: '/system/menu/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除菜单
 * @param {*} data
 */
function deleteMenu(data) {
  return (0, _request.default)({
    url: '/system/menu/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取角色列表
 * @param {*} params
 */
function roleList(params) {
  return (0, _request.default)({
    url: '/system/role/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑角色
 * @param {*} data
 */
function editRole(data) {
  return (0, _request.default)({
    url: '/system/role/edit',
    method: 'post',
    data: data
  });
}

/**
 * 编辑角色菜单
 * @param {*} data
 */
function editRoleMenu(data) {
  return (0, _request.default)({
    url: '/system/role/menu/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取mq执行失败列表
 * @param {*} params
 */
function mqRecordList(params) {
  return (0, _request.default)({
    url: '/system/mqRecord/list',
    method: 'get',
    params: params
  });
}

/**
 * 重试mq消息
 */
function sendMqMessage(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_API_JAVA_PATH, "/oapi/tools/retrySendingMessage"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  });
}

/**
 * 删除mq消息
 */
function delMqMessage(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_API_JAVA_PATH, "/oapi/tools/deleteMessage"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  });
}