"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.handleEdit(null);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("table.add")))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "username",
      label: _vm.$t("table.userName")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "area_code",
      label: "管辖区域"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("areaCodeFilter")(row.area_code)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "updated_at",
      label: _vm.$t("table.lastLogin")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeFilter")(row.updated_at, "yyyy-MM-dd hh:mm:ss")) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "created_at",
      label: _vm.$t("table.createdTime")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeFilter")(row.created_at, "yyyy-MM-dd hh:mm:ss")) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "role_name",
      label: "角色"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "isBindGoogle",
      label: "是否已激活谷歌身份验证器"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(row);
            }
          }
        }, [_vm._v("编辑")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.delAdmin(row);
            }
          }
        }, [_vm._v("删除")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.resetGoogle(row);
            }
          }
        }, [_vm._v("重置谷歌验证")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "text"
          },
          on: {
            click: function click($event) {
              return _vm.resetPwd(row);
            }
          }
        }, [_vm._v("重置密码")])];
      }
    }])
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: _vm.dialogType === "edit" ? _vm.$t("dialog.editAccount") : _vm.$t("dialog.addAccount"),
      width: "400px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "editForm",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-width": "80px",
      "label-position": "right"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("form.userName"),
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "".concat(_vm.$t("form.pleaseEnter")).concat(_vm.$t("form.userName"))
    },
    model: {
      value: _vm.formData.username,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "username", $$v);
      },
      expression: "formData.username"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "角色"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "角色"
    },
    model: {
      value: _vm.formData.role_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "role_id", $$v);
      },
      expression: "formData.role_id"
    }
  }, _vm._l(_vm.roleLists, function (item) {
    return _c("el-option", {
      key: item.role_id,
      attrs: {
        label: item.label,
        value: item.role_id
      }
    });
  }), 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      placement: "top"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v("超管：管理后台所有功能权限、权限配置 "), _c("br"), _vm._v(" 高级运营： 人工充值、开通VIP、用户资料修改 "), _c("br"), _vm._v(" 运营：正常功能 "), _c("br"), _vm._v(" 客服：仅包含客服以及用户主播管理功能"), _c("br"), _vm._v(" 财务：包含财务相关功能"), _c("br"), _vm._v(" 合作方：部分全应用数据 ")]), _vm._v(" "), _c("svg-icon", {
    staticClass: "tips",
    attrs: {
      "icon-class": "tip"
    }
  })], 1)], 1), _vm._v(" "), _vm.formData.role_id === 2 ? _c("el-form-item", {
    attrs: {
      label: "管辖区域"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      clearable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.area_code,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "area_code", $$v);
      },
      expression: "formData.area_code"
    }
  }, _vm._l(_vm.areaCodeList, function (item) {
    return _c("el-option", {
      key: item.area_code,
      attrs: {
        label: item.area_code,
        value: item.area_code
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("form.cancel")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmRole
    }
  }, [_vm._v(_vm._s(_vm.$t("form.confirm")))])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;