"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _validate = require("@/utils/validate");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      lockBox: false,
      form: {
        passwd: ''
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['lockPasswd'])),
  methods: {
    handleLock: function handleLock() {
      var _this = this;
      if ((0, _validate.validatenull)(this.lockPasswd)) {
        this.lockBox = true;
        return;
      }
      this.$store.commit('user/SET_LOCK');
      setTimeout(function () {
        _this.$router.push({
          path: "/lock?redirect=".concat(_this.$route.path)
        });
      }, 100);
    },
    handleSetLock: function handleSetLock() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this2.$store.commit('user/SET_LOCK_PASSWD', _this2.form.passwd);
          _this2.handleLock();
        }
      });
    }
  }
};