"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./admin"));
var _editor = _interopRequireDefault(require("./editor"));
var _brokerDashboard = _interopRequireDefault(require("./brokerDashboard"));
var _videoDashboard = _interopRequireDefault(require("./videoDashboard.vue"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default,
    editorDashboard: _editor.default,
    brokerDashboard: _brokerDashboard.default,
    videoDashboard: _videoDashboard.default
  },
  data: function data() {
    return {
      currentRole: 'adminDashboard'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['roles', 'name'])),
  created: function created() {
    if (this.roles.length && !['admin', 'operation', 'superAdmin', 'customer', 'development', 'product', 'market', 'finance', 'projectManager'].includes(this.roles[0])) {
      if (this.roles.includes('broker')) {
        this.currentRole = 'brokerDashboard';
      } else if (this.roles.includes('reviewVideo')) {
        this.currentRole = 'videoDashboard';
      } else {
        this.currentRole = '';
      }
    }
  }
};