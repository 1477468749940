"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.number.constructor");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd",
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": _vm.$t("form.startDate"),
      "end-placeholder": _vm.$t("form.endDate")
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search",
      disabled: _vm.disabled
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.disabled ? "Loading..." : _vm.$t("table.search")))])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 12,
      lg: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-people"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "peoples",
      "class-name": "card-panel-icon"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v("\n            " + _vm._s(_vm.$t("dashboard.totalPaid")) + "\n          ")]), _vm._v(" "), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.daysChargeCount,
      duration: 3000
    }
  })], 1)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 12,
      lg: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "money",
      "class-name": "card-panel-icon"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v("\n            " + _vm._s(_vm.$t("dashboard.totalAmountOfUsers")) + "\n          ")]), _vm._v(" "), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": Number(_vm.dashboardData.daysChargeAllChannelDouble),
      duration: 3000,
      decimals: 2
    }
  })], 1)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 12,
      lg: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "money",
      "class-name": "card-panel-icon"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v("\n            " + _vm._s(_vm.$t("dashboard.settledVIPAmount")) + "\n          ")]), _vm._v(" "), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": Number(_vm.dashboardData.vipAmount),
      duration: 3000,
      decimals: 2
    }
  })], 1)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 12,
      lg: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "money",
      "class-name": "card-panel-icon"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v("\n            " + _vm._s(_vm.$t("dashboard.actualSettlementAmountOfPlatform")) + "\n          ")]), _vm._v(" "), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": Number(_vm.dashboardData.platformMoney),
      duration: 3000,
      decimals: 2
    }
  })], 1)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 8,
      span: 8
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_vm._v("\n          主播数据\n        ")]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播总数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.anchorCount || 0))])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("新增主播数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.newAnchorNum || 0))])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("通话主播")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.daysActiveAnchorAllChannel || 0))])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("活跃主播")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.activeOfAnchor || 0))])])])])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 8,
      span: 8
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_vm._v("\n          用户数据\n        ")]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("新增用户")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.daysNew || 0))])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("新增充值人")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.daysNewCharge || 0))])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("新增用户付费率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.userPayRotes || 0) + "%")])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("活跃用户")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.activeOfUser || 0))])])])])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 8,
      span: 8
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_vm._v("\n          心跳数据\n        ")]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("在线总数-主播加用户")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.userAnchorSum || 0))])]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("当前正常链接数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.imConnect || 0))])])])])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 24
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_vm._v("\n          通话统计\n        ")]), _vm._v(" "), _c("el-row", {
    staticClass: "card-panel-content"
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      lg: 5,
      span: 5
    }
  }, [_c("ul", [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("用户点击拨打次数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfUserCall,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("用户有效拨打且5s未挂断数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.allCalledVideoVaildNum,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("用户发起拨打有效数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfUserCallSucc,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("用户发起有效率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalNumberOfUserCallRate,
      duration: 2600,
      suffix: "%"
    }
  })], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      lg: 5,
      span: 5
    }
  }, [_c("ul", [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播点击拨打次数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfAnchorCall,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播发起拨打总数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfAnchorCallCount,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播发起拨打有效数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfAnchorCallSucc,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播发起有效率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalNumberOfAnchorCallRate,
      duration: 2600,
      suffix: "%"
    }
  })], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      lg: 5,
      span: 5
    }
  }, [_c("ul", [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("全局点击拨打次数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfCall,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("全局发起拨打总数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfCallCount,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("全局发起拨打有效数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfCallSucc,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("全局发起有效率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalNumberOfCallRate,
      duration: 2600,
      suffix: "%"
    }
  })], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      lg: 5,
      span: 5
    }
  }, [_c("ul", [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("用户接通次数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfUserCallSuccess,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播接通次数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfAnchorCallSuccess,
      duration: 2600
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("全局接通次数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dashboardData.totalNumberOfCallSuccess,
      duration: 2600
    }
  })], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      lg: 4,
      span: 4
    }
  }, [_c("ul", [_c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("用户接通率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalNumberOfUserConnectedRate,
      duration: 2600,
      suffix: "%"
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("主播接通率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalNumberOfAnchorConnectedRate,
      duration: 2600,
      suffix: "%"
    }
  })], 1)]), _vm._v(" "), _c("li", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "list-line"
  }), _vm._v(" "), _c("span", {
    staticClass: "list-label"
  }, [_vm._v("全局接通率")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.totalNumberOfConnectedRate,
      suffix: "%",
      duration: 2600
    }
  })], 1)])])])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 4,
      span: 4
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_c("span", {
    staticClass: "list-label"
  }, [_vm._v("当前在线主播数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.onlineAnchorCount || 0))])]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, _vm._l(_vm.dashboardData.anchorCountryList, function (item) {
    return _c("li", {
      key: item,
      staticClass: "list"
    }, [_c("div", {
      staticClass: "list-line"
    }), _vm._v(" "), _c("span", {
      staticClass: "list-label"
    }, [_vm._v(_vm._s(item.country_code))]), _vm._v(" "), _c("span", {
      staticClass: "list-value"
    }, [_vm._v(_vm._s(item.count))])]);
  }), 0)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 4,
      span: 4
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_c("span", {
    staticClass: "list-label"
  }, [_vm._v("余额充足在线空闲用户数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.onlineUserCount || 0))])]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, _vm._l(_vm.dashboardData.onlineUserCountList, function (item) {
    return _c("li", {
      key: item,
      staticClass: "list"
    }, [_c("div", {
      staticClass: "list-line"
    }), _vm._v(" "), _c("span", {
      staticClass: "list-label"
    }, [_vm._v(_vm._s(item.country_code))]), _vm._v(" "), _c("span", {
      staticClass: "list-value"
    }, [_vm._v(_vm._s(item.count))])]);
  }), 0)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 6,
      span: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_c("span", {
    staticClass: "list-label"
  }, [_vm._v("仅有免费次数且在线空闲用户数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.videoCountUserNum))])]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, _vm._l(_vm.dashboardData.videoCountUserList, function (item) {
    return _c("li", {
      key: item,
      staticClass: "list"
    }, [_c("div", {
      staticClass: "list-line"
    }), _vm._v(" "), _c("span", {
      staticClass: "list-label"
    }, [_vm._v(_vm._s(item.country_code))]), _vm._v(" "), _c("span", {
      staticClass: "list-value"
    }, [_vm._v(_vm._s(item.count))])]);
  }), 0)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 4,
      span: 4
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_c("span", {
    staticClass: "list-label"
  }, [_vm._v("当前在线用户数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.onlineAllUserCount || 0) + " - "), _c("el-tag", {
    on: {
      click: _vm.showOnline
    }
  }, [_vm._v("详情")])], 1)]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, _vm._l(_vm.dashboardData.onlineAllUserList, function (item) {
    return _c("li", {
      key: item,
      staticClass: "list"
    }, [_c("div", {
      staticClass: "list-line"
    }), _vm._v(" "), _c("span", {
      staticClass: "list-label"
    }, [_vm._v(_vm._s(item.country_code))]), _vm._v(" "), _c("span", {
      staticClass: "list-value"
    }, [_vm._v(_vm._s(item.count))])]);
  }), 0)])]), _vm._v(" "), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 24,
      sm: 24,
      lg: 6,
      span: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-title"
  }, [_c("span", {
    staticClass: "list-label"
  }, [_vm._v("当前在线付过费余额不足用户数")]), _vm._v(" "), _c("span", {
    staticClass: "list-value"
  }, [_vm._v(_vm._s(_vm.dashboardData.oldCoinUserNum || 0))])]), _vm._v(" "), _c("ul", {
    staticClass: "card-panel-content"
  }, _vm._l(_vm.dashboardData.oldCoinUserList, function (item) {
    return _c("li", {
      key: item,
      staticClass: "list"
    }, [_c("div", {
      staticClass: "list-line"
    }), _vm._v(" "), _c("span", {
      staticClass: "list-label"
    }, [_vm._v(_vm._s(item.country_code))]), _vm._v(" "), _c("span", {
      staticClass: "list-value"
    }, [_vm._v(_vm._s(item.count))])]);
  }), 0)])])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogShowOnline,
      title: "在线用户列表详情",
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogShowOnline = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("用户ID")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("上线时间")])], 1), _c("br"), _vm._v(" "), _vm._l(_vm.dashboardData.onlineAllUserIdsList.length / 2, function (items, index) {
    return _c("el-row", {
      key: items,
      attrs: {
        gutter: 24
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v(_vm._s(_vm.dashboardData.onlineAllUserIdsList[index * 2].replaceAll('"', "")))]), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v(_vm._s(_vm._f("timeToDateFilter")(parseInt(_vm.dashboardData.onlineAllUserIdsList[index * 2 + 1]))))])], 1);
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        _vm.dialogShowOnline = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;