"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
var _default = exports.default = {
  directives: {
    waves: _waves.default
  },
  props: {
    videoWidth: {
      type: String,
      default: '100px'
    },
    videoHeight: {
      type: String,
      default: '130px'
    },
    playbackRate: {
      type: Number,
      default: 1.0
    },
    videoUrl: {
      type: String,
      required: true
    },
    picUrl: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      picDefUrl: this.picUrl || 'http://cdn-media-de.hala.icu/goods/video.png',
      isPlaying: false
    };
  },
  watch: {
    videoUrl: function videoUrl(newVal, oldVal) {
      this.videoUrl = newVal;
    },
    picUrl: function picUrl(newVal, oldVal) {
      this.picUrl = newVal;
    }
  },
  created: function created() {},
  methods: {
    playVideo: function playVideo() {
      var _this = this;
      // 首先判断是否正在播放或者已经销毁
      if (this.isPlaying) {
        return;
      }
      // 替换图片为视频
      this.isPlaying = true;
      if (this.playbackRate > 1) {
        this.$nextTick(function () {
          _this.$refs.myVideo.playbackRate = _this.playbackRate;
        });
      }
    },
    stopVideo: function stopVideo() {
      // 销毁视频并恢复图片
      this.isPlaying = false;
    }
  }
};