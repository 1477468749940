"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _vm._v(" "), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_vm.device !== "mobile" ? [_c("lock", {
    staticClass: "right-menu-item"
  }), _vm._v(" "), _c("search", {
    staticClass: "right-menu-item",
    attrs: {
      id: "header-search"
    }
  }), _vm._v(" "), _c("screenfull", {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      id: "screenfull"
    }
  }), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      content: _vm.$t("navbar.size"),
      effect: "dark",
      placement: "bottom"
    }
  }, [_c("size-select", {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      id: "size-select"
    }
  })], 1), _vm._v(" "), _c("lang-select", {
    staticClass: "right-menu-item hover-effect"
  })] : _vm._e(), _vm._v(" "), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80"
    }
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_c("el-dropdown-item", [_vm._v("\n            " + _vm._s(_vm.$t("navbar.dashboard")) + "\n          ")])], 1), _vm._v(" "), !_vm.isBindGoogleAuth && _vm.isShowGoogleCode ? _c("el-dropdown-item", [_c("google-auth")], 1) : _vm._e(), _vm._v(" "), _c("el-dropdown-item", [_c("password")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    },
    nativeOn: {
      click: function click($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.logOut")))])])], 1)], 1)], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;