"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    attrs: {
      type: "card"
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: _vm.$t("table.firstExamina"),
      name: "firstExamina"
    }
  }, [_c("first-examina")], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("table.reviewExamina"),
      name: "reviewExamina"
    }
  }, [_c("review-examina")], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;