"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anchorNatural = anchorNatural;
exports.banDevice = banDevice;
exports.banWallet = banWallet;
exports.channelAuthority = channelAuthority;
exports.checking = checking;
exports.clearDevice = clearDevice;
exports.clearPayment = clearPayment;
exports.clearRedisBalance = clearRedisBalance;
exports.clearUserbills = clearUserbills;
exports.clearVip = clearVip;
exports.clearWallet = clearWallet;
exports.commentList = commentList;
exports.commentVideoDelete = commentVideoDelete;
exports.commentVideoList = commentVideoList;
exports.delGiveFreeVideo = delGiveFreeVideo;
exports.deleteMoment = deleteMoment;
exports.deleteRobot = deleteRobot;
exports.editPsd = editPsd;
exports.editRobotDetail = editRobotDetail;
exports.editSystemAccount = editSystemAccount;
exports.editUserDetail = editUserDetail;
exports.editUserVip = editUserVip;
exports.extendBillsList = extendBillsList;
exports.extendBlackList = extendBlackList;
exports.extendCallRecord = extendCallRecord;
exports.extendFriendsList = extendFriendsList;
exports.extendGiveFreeVideo = extendGiveFreeVideo;
exports.extendImRecord = extendImRecord;
exports.extendWalletInformation = extendWalletInformation;
exports.getInfo = getInfo;
exports.getRobotDetail = getRobotDetail;
exports.getSystemAccount = getSystemAccount;
exports.getUserByIds = getUserByIds;
exports.getUserDetail = getUserDetail;
exports.handleSynchro = handleSynchro;
exports.imStatus = imStatus;
exports.imageList = imageList;
exports.login = login;
exports.logout = logout;
exports.marketReviewsEdit = marketReviewsEdit;
exports.marketReviewsList = marketReviewsList;
exports.momentDelete = momentDelete;
exports.momentEdit = momentEdit;
exports.momentList = momentList;
exports.pushUser = pushUser;
exports.queryPsd = queryPsd;
exports.rankFilter = rankFilter;
exports.reviewAvatarNaruralEdit = reviewAvatarNaruralEdit;
exports.reviewAvatarNaruralList = reviewAvatarNaruralList;
exports.reviewEdit = reviewEdit;
exports.reviewList = reviewList;
exports.reviewUserPhoneDelete = reviewUserPhoneDelete;
exports.reviewUserPhoneEdit = reviewUserPhoneEdit;
exports.reviewUserPhoneList = reviewUserPhoneList;
exports.reviewVideoEdit = reviewVideoEdit;
exports.reviewVideoList = reviewVideoList;
exports.robotList = robotList;
exports.robotUserSynchro = robotUserSynchro;
exports.statusDevice = statusDevice;
exports.synchroDevice = synchroDevice;
exports.unMuted = unMuted;
exports.unsetDiscourse = unsetDiscourse;
exports.updateUserStatus = updateUserStatus;
exports.userAdjustId = userAdjustId;
exports.userList = userList;
exports.userSynchroList = userSynchroList;
exports.userVideoEdit = userVideoEdit;
exports.userVideoList = userVideoList;
exports.userVipDetail = userVipDetail;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var _auth = require("@/utils/auth");
/**
 * 系统登陆
 * @param {*} data
 */
function login(data) {
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    data: data
  });
}

/**
 * 获取登陆详细信息
 * @param {*} token
 */
function getInfo(token) {
  return (0, _request.default)({
    url: '/login/info',
    method: 'get',
    params: {
      token: token
    }
  });
}

/**
 * 登出
 */
function logout() {
  return (0, _request.default)({
    url: '/logout',
    method: 'post'
  });
}

/**
 * 获取用户列表
 * @param {*} params
 */
function userList(params) {
  return (0, _request.default)({
    url: '/user/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取审核用户列表
 * @param {*} params
 */
function userSynchroList(params) {
  return (0, _request.default)({
    url: '/user/synchroList',
    method: 'get',
    params: params
  });
}

/**
 * 获取机器人列表
 * @param {*} params
 */
function robotList(params) {
  return (0, _request.default)({
    url: '/robot/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取机器人详情
 * @param {*} params
 */
function getRobotDetail(params) {
  return (0, _request.default)({
    url: '/robot/detail',
    method: 'GET',
    params: params
  });
}

/**
 * 修改机器人信息
 * @param {*} data
 */
function editRobotDetail(data) {
  return (0, _request.default)({
    url: '/user/editRobotDetail',
    method: 'post',
    data: data
  });
}

/**
 * 删除机器人
 * @param {*} data
 */
function deleteRobot(data) {
  return (0, _request.default)({
    url: '/user/deleteRobot',
    method: 'post',
    data: data
  });
}

/**
 * 重置机器人主播策略绑定
 * @param {*} data
 */
function unsetDiscourse(data) {
  return (0, _request.default)({
    url: '/user/unsetDiscourse',
    method: 'post',
    data: data
  });
}

/**
 * 获取用户动态列表
 * @param {*} data
 */
function momentList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/user/moment/list"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
// export function momentList(params) {
//   return request({
//     url: '/user/moment/list',
//     method: 'get',
//     params
//   })
// }

/**
 * 审核动态
 * @param {*} data
 */
function momentEdit(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/user/moment/edit"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
// export function momentEdit(data) {
//   return request({
//     url: '/user/moment/edit',
//     method: 'post',
//     data
//   })
// }

/**
 * 删除动态
 * @param {*} data
 */
function momentDelete(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/user/moment/delete"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
// export function momentDelete(data) {
//   return request({
//     url: '/user/moment/delete',
//     method: 'post',
//     data
//   })
// }

/**
 * 删除动态
 * @param {*} data
 */
function deleteMoment(data) {
  return (0, _request.default)({
    url: '/user/moment/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取动态评论列表
 * @param {*} data
 */
function commentList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/user/momentComment/review"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
// export function commentList(params) {
//   return request({
//     url: '/user/comment/list',
//     method: 'get',
//     params
//   })
// }

/**
 * 删除评论
 * @param {*} data
 */
// export function commentDelete(data) {
//   return request({
//     url: '/user/comment/delete',
//     method: 'post',
//     data
//   })
// }

/**
 * 获取视频秀评论列表
 * @param {*} params
 */
function commentVideoList(params) {
  return (0, _request.default)({
    url: '/user/commentVideo/list',
    method: 'get',
    params: params
  });
}

/**
 * 删除视频秀评论
 * @param {*} data
 */
function commentVideoDelete(data) {
  return (0, _request.default)({
    url: '/user/commentVideo/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取动态图片库
 * @param {*} params
 */
function imageList(params) {
  return (0, _request.default)({
    url: '/user/image/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取商店评分列表
 * @param {*} params
 */
function marketReviewsList(params) {
  return (0, _request.default)({
    url: '/user/marketReviewsList',
    method: 'get',
    params: params
  });
}

/**
 * 审核商店评分信息
 * @param {*} data
 */
function marketReviewsEdit(data) {
  return (0, _request.default)({
    url: '/user/marketReviewsEdit',
    method: 'post',
    data: data
  });
}

/**
 * 获取用户详情
 * @param {*} params
 */
function getUserDetail(params) {
  return (0, _request.default)({
    url: '/user/detail',
    method: 'GET',
    params: params
  });
}

/**
 * 获取用户VIP信息
 * @param {*} params
 */
function userVipDetail(params) {
  return (0, _request.default)({
    url: '/user/userVipDetail',
    method: 'GET',
    params: params
  });
}

/**
 * 更新用户会员信息
 * @param {*} data
 */
function editUserVip(data) {
  return (0, _request.default)({
    url: '/user/editUserVip',
    method: 'post',
    data: data
  });
}

/**
 * 更新用户资料
 * @param {*} data
 */
function editUserDetail(data) {
  return (0, _request.default)({
    url: '/user/editUserDetail',
    method: 'post',
    data: data
  });
}

/**
 * 查询密码
 * @param {*} data
 */
function queryPsd(params) {
  return (0, _request.default)({
    url: '/user/queryPsd',
    method: 'GET',
    params: params
  });
}

/**
 * 同步用户信息到云信
 * @param {*} data
 */
function handleSynchro(data) {
  return (0, _request.default)({
    url: '/user/handleSynchro',
    method: 'post',
    data: data
  });
}

/**
 * 清理用户钱包
 * @param {*} data
 */
function clearWallet(data) {
  return (0, _request.default)({
    url: '/user/clearWallet',
    method: 'post',
    data: data
  });
}

/**
 * 更改用户状态
 * @param {*} data
 */
function updateUserStatus(data) {
  return (0, _request.default)({
    url: '/user/updateStatus',
    method: 'post',
    data: data
  });
}

/**
 * 清理用户设备与三方登录
 * @param {*} data
 */
function clearDevice(data) {
  return (0, _request.default)({
    url: '/user/clearDevice',
    method: 'post',
    data: data
  });
}

/**
 * 取消VIP身份
 * @param {*} data
 */
function clearVip(data) {
  return (0, _request.default)({
    url: '/user/clearVip',
    method: 'post',
    data: data
  });
}

/**
 * 清理用户支付订单记录
 * @param {*} data
 */
function clearPayment(data) {
  return (0, _request.default)({
    url: '/user/clearPayment',
    method: 'post',
    data: data
  });
}

/**
 * 清流水记录
 * @param {*} data
 */
function clearUserbills(data) {
  return (0, _request.default)({
    url: '/user/clearUserbills',
    method: 'post',
    data: data
  });
}
/**
 * 封禁设备
 * @param {*} data
 */
function banDevice(data) {
  return (0, _request.default)({
    url: '/user/banDevice',
    method: 'post',
    data: data
  });
}
/**
 * 验证
 * @param {*} data
 */
function checking(params) {
  return (0, _request.default)({
    url: '/user/checking',
    method: 'GET',
    params: params
  });
}
/**
 * 根据多个ID获取用户信息（用于客服聊天用户信息获取）
 * @param {*} params { ids: '用户ID集合', type: '0: 单个搜索', 1: '查询列表', 2: '置顶列表' }
 */
function getUserByIds(data) {
  return (0, _request.default)({
    url: '/user/ids/info/list',
    method: 'POST',
    data: data
  });
}
/**
 * 修改密码
 * @param {*} data
 */
function editPsd(data) {
  return (0, _request.default)({
    url: '/user/editPsd',
    method: 'post',
    data: data
  });
}

/**
 * 获取审核信息列表
 * @param {*} params
 */
function reviewList(params) {
  return (0, _request.default)({
    url: '/user/review/list',
    method: 'get',
    params: params
  });
}

/**
 * 审核用户信息
 * @param {*} data
 */
function reviewEdit(data) {
  return (0, _request.default)({
    url: '/user/review/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取审核B面头像列表
 * @param {*} params
 */
function reviewAvatarNaruralList(params) {
  return (0, _request.default)({
    url: '/user/reviewAvatarNarural/list',
    method: 'get',
    params: params
  });
}

/**
 * 审核B面头像
 * @param {*} data
 */
function reviewAvatarNaruralEdit(data) {
  return (0, _request.default)({
    url: '/user/reviewAvatarNarural/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取用户相册列表
 * @param {*} params
 */
function reviewUserPhoneList(params) {
  return (0, _request.default)({
    url: '/user/reviewUserPhone/list',
    method: 'get',
    params: params
  });
}

/**
 * 审核用户相册
 * @param {*} data
 */
function reviewUserPhoneEdit(data) {
  return (0, _request.default)({
    url: '/user/reviewUserPhone/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除用户相册
 * @param {*} data
 */
function reviewUserPhoneDelete(data) {
  return (0, _request.default)({
    url: '/user/reviewUserPhone/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取审核视频秀列表
 * @param {*} params
 */
function reviewVideoList(params) {
  return (0, _request.default)({
    url: '/user/reviewVideo/list',
    method: 'get',
    params: params
  });
}

/**
 * 审核视频秀
 * @param {*} data
 */
function reviewVideoEdit(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/anchorData/review/userVideos"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
// export function reviewVideoEdit(data) {
//   return request({
//     url: '/user/reviewVideo/edit',
//     method: 'post',
//     data
//   })
// }

/**
 * 获取B面资料
 * @param {*} params
 */
function anchorNatural(params) {
  return (0, _request.default)({
    url: '/user/anchorNatural/list',
    method: 'get',
    params: params
  });
}

/**
 * 用户推送消息
 * @param {*} data
 */
function pushUser(data) {
  return (0, _request.default)({
    url: '/user/push',
    method: 'post',
    data: data
  });
}

/**
 * 查询用户在线状态
 * @param {*} params
 */
function imStatus(params) {
  return (0, _request.default)({
    url: '/user/imStatus',
    method: 'get',
    params: params
  });
}

/**
 * 解除禁言
 * @param {*} data
 */
function unMuted(data) {
  return (0, _request.default)({
    url: '/user/unmuted',
    method: 'post',
    data: data
  });
}

/**
 * 获取用户IM上下线记录
 * @param {*} params
 */
function extendImRecord(params) {
  return (0, _request.default)({
    url: '/user/extendImRecord',
    method: 'get',
    params: params
  });
}

/**
 * 获取黑名单列表
 * @param {*} params
 */
function extendBlackList(params) {
  return (0, _request.default)({
    url: '/user/extendBlackList',
    method: 'get',
    params: params
  });
}

/**
 * 获取用户好友列表
 * @param {*} params
 */
function extendFriendsList(params) {
  return (0, _request.default)({
    url: '/user/extendFriendsList',
    method: 'get',
    params: params
  });
}

/**
 * 获取用户通话记录
 * @param {*} params
 */
function extendCallRecord(params) {
  return (0, _request.default)({
    url: '/user/extendCallRecord',
    method: 'get',
    params: params
  });
}

/**
 * 获取用户通话记录
 * @param {*} params
 */
function extendBillsList(params) {
  return (0, _request.default)({
    url: '/user/extendBillsList',
    method: 'get',
    params: params
  });
}

/**
 * 获取用户钱包缓存信息
 * @param {*} params
 */
function extendWalletInformation(params) {
  return (0, _request.default)({
    url: '/user/extendWalletInformation',
    method: 'get',
    params: params
  });
}

/**
 * 获取赠送记录信息
 * @param {*} params
 */
function extendGiveFreeVideo(params) {
  return (0, _request.default)({
    url: '/user/extendGiveFreeVideo',
    method: 'get',
    params: params
  });
}

/**
 * 清楚赠送记录信息
 * @param {*} data
 */
function delGiveFreeVideo(data) {
  return (0, _request.default)({
    url: '/user/delGiveFreeVideo',
    method: 'post',
    data: data
  });
}

/**
 * 清理用户的redis钱包数据
 * @param {*} data
 */
function clearRedisBalance(data) {
  return (0, _request.default)({
    url: '/user/clearRedisBalance',
    method: 'post',
    data: data
  });
}

/**
 * 冻结解冻用户钱包
 * @param {*} data
 */
function banWallet(data) {
  return (0, _request.default)({
    url: '/user/banWallet',
    method: 'post',
    data: data
  });
}

/**
 * 获取系统账户
 * @param {*} params
 */
function getSystemAccount(params) {
  return (0, _request.default)({
    url: '/user/getSystemAccount',
    method: 'get',
    params: params
  });
}

/**
 * 生成或修改系统账户
 * @param {*} data
 */
function editSystemAccount(data) {
  return (0, _request.default)({
    url: '/user/editSystemAccount',
    method: 'post',
    data: data
  });
}

/**
 * 获取设备封禁状态
 * @param {*} data
 */
function statusDevice(data) {
  return (0, _request.default)({
    url: '/user/statusDevice',
    method: 'post',
    data: data
  });
}

/**
 * 同步用户设备id至设备表
 * @param {*} data
 */
function synchroDevice(data) {
  return (0, _request.default)({
    url: '/user/synchroDevice',
    method: 'post',
    data: data
  });
}

/**
 * 渠道后台获取分销参数
 * @param {*} data
 */
function channelAuthority(data) {
  return (0, _request.default)({
    url: '/user/channelAuthority',
    method: 'post',
    data: data
  });
}

/**
 * 渠道后台获取分销参数
 * @param {*} data
 */
function robotUserSynchro(data) {
  return (0, _request.default)({
    url: '/user/robotUserSynchro',
    method: 'post',
    data: data
  });
}

/**
 * 过滤用户排行榜数据
 * @param {*} params
 */
function rankFilter(params) {
  return (0, _request.default)({
    url: '/user/rankFilter',
    method: 'GET',
    params: params
  });
}

/**
 * 获取用户Adjust_Id信息
 * @param {*} params
 */
function userAdjustId(params) {
  return (0, _request.default)({
    url: '/user/userAdjustId',
    method: 'GET',
    params: params
  });
}

/**
 * 获取用户视频列表
 * @param {*} params
 */
function userVideoList(params) {
  return (0, _request.default)({
    url: '/user/userVideoList',
    method: 'get',
    params: params
  });
}

/**
 * 审核用户视频列表
 * @param {*} data
 */
function userVideoEdit(data) {
  return (0, _request.default)({
    url: '/user/userVideoEdit',
    method: 'post',
    data: data
  });
}