"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add0 = add0;
exports.addClass = addClass;
exports.buildDataKeyGroup = buildDataKeyGroup;
exports.byteLength = byteLength;
exports.checkoutArraySame = checkoutArraySame;
exports.cleanArray = cleanArray;
exports.compareDate = compareDate;
exports.createUniqueString = createUniqueString;
exports.customToFixed = customToFixed;
exports.dataDeduplication = dataDeduplication;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.formatDeta = formatDeta;
exports.formatTime = formatTime;
exports.getQueryObject = getQueryObject;
exports.getTime = getTime;
exports.getTimeList = getTimeList;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.isEmpty = isEmpty;
exports.isJSON = isJSON;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.removeClass = removeClass;
exports.replaceUnderLine = replaceUnderLine;
exports.stringToJson = stringToJson;
exports.timeToDate = timeToDate;
exports.timeToUTCDate = timeToUTCDate;
exports.timeToUTCDateFilter = timeToUTCDateFilter;
exports.toggleClass = toggleClass;
exports.uniqueArr = uniqueArr;
var _toConsumableArray2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.map");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * 时间格式化工具
 * @param {*} time 时间
 * @param {*} formatStr 格式
 */
function formatDeta(time, formatStr) {
  var date = new Date(time);
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  };
  if (/(y+)/.test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(formatStr)) formatStr = formatStr.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }
  return formatStr;
}

/**
 * 将时间戳转为UTC时间格式
 * @param {*} time 时间戳
 */
function timeToUTCDateFilter(timestamp) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd hh:mm:ss';
  if (!timestamp) return '';
  var date = new Date(timestamp);
  var o = {
    'M+': date.getUTCMonth() + 1,
    'd+': date.getUTCDate(),
    'h+': date.getUTCHours(),
    'm+': date.getUTCMinutes(),
    's+': date.getUTCSeconds(),
    'q+': Math.floor((date.getUTCMonth() + 3) / 3),
    'S': date.getUTCMilliseconds()
  };
  if (/(y+)/.test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (date.getUTCFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(formatStr)) formatStr = formatStr.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }
  return formatStr;
}

/**
 * 将时间戳转为UTC时间格式
 * @param {*} time 时间戳
 */
function timeToUTCDate(timestamp, formatStr) {
  if (!timestamp) return '';
  var time = new Date(timestamp);
  var o = {
    'M+': time.getUTCMonth() + 1,
    'd+': time.getUTCDate(),
    'h+': time.getUTCHours(),
    'm+': time.getUTCMinutes(),
    's+': time.getUTCSeconds(),
    'q+': Math.floor((time.getUTCMonth() + 3) / 3),
    S: time.getUTCMilliseconds()
  };
  if (/(y+)/.test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (time.getUTCFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(formatStr)) formatStr = formatStr.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }
  return formatStr;
}

/**
 * 格式化时间
 * @param {*} m 值
 */
function add0(m) {
  return m < 10 ? '0' + m : m;
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/**
 * 检测两个数组中相同元素
 * @param {*} sourceArr
 * @param {*} targetArr
 */
function checkoutArraySame(sourceArr, targetArr) {
  var resultArr = [];
  var _iterator = (0, _createForOfIteratorHelper2.default)(targetArr),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var val = _step.value;
      if (sourceArr.includes(val)) resultArr.push(val);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return resultArr;
}

/**
 * 非json字符串转json
 * @param {*} str 字符串
 */
function stringToJson(str) {
  var obj = {};
  str = /\{(.*)\}/.exec(str)[1];
  str.split(',').forEach(function (item) {
    var temp = item.split(':');
    obj[temp[0]] = temp[1].replace(/(\'|\")/g, '');
  });
  return obj;
}

/**
 * 判断是否为空
 * @param {*} val 需要校验的值
 */
function isEmpty(val) {
  if (val === undefined || val === null || typeof val === 'string' && val.trim() === '') {
    return true;
  }
  return false;
}

/**
 * 字段名下划线转驼峰命名
 * @param {*} val 字段名
 */
function replaceUnderLine(val) {
  return val.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}

/**
 * [getTimeList description] 生成时间列表
 * @param  {[type]} step  [description] 分段
 * @return {[type]}       [description] 时间段列表
 */
function getTimeList(step) {
  var date = new Date();
  date.setHours(0);
  // 时分秒设置从零点开始
  date.setSeconds(0);
  date.setUTCMinutes(0);
  // console.log(date.getHours())
  // console.log(date.getSeconds())
  // console.log(new Date(date.getTime()))

  // const arr = []
  var timeArr = [];
  var slotNum = 24 * 60 / step;
  // 算出多少个间隔
  for (var f = 0; f < slotNum; f++) {
    //  stepM * f = 24H*60M
    // arr.push(new Date(Number(date.getTime()) + Number(step*60*1000*f)))   // 标准时间数组
    var time = new Date(Number(date.getTime()) + Number(step * 60 * 1000 * f)); // 获取：零点的时间 + 每次递增的时间
    var hour = '';
    var sec = '';
    time.getHours() < 10 ? hour = '0' + time.getHours() : hour = time.getHours(); // 获取小时
    time.getMinutes() < 10 ? sec = '0' + time.getMinutes() : sec = time.getMinutes(); // 获取分钟
    timeArr.push(hour + ':' + sec);
  }
  return timeArr;
}

/**
 * t1是否小于t2
 * @param {*} t1 时间
 * @param {*} t2 时间
 * @param {*} operatorType 比较类型
 * @returns true or false
 */
function compareDate(t1, t2) {
  var operatorType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
  if (!t1 || !t2) {
    return true;
  }
  var date = new Date();
  var a = t1.split(':');
  var b = t2.split(':');
  if (operatorType === -1) return date.setHours(a[0], a[1]) < date.setHours(b[0], b[1]);
  if (operatorType === 0) return date.setHours(a[0], a[1]) === date.setHours(b[0], b[1]);
  if (operatorType === 1) return date.setHours(a[0], a[1]) > date.setHours(b[0], b[1]);
}

/**
 * 时间转换
 * @param {*} timestamp 时间
 * @returns 时间
 */
function timeToDate(timestamp) {
  var unixtimestamp = new Date(timestamp);
  var year = 1900 + unixtimestamp.getYear();
  var month = '0' + (unixtimestamp.getMonth() + 1);
  var date = '0' + unixtimestamp.getDate();
  var hour = '0' + unixtimestamp.getHours();
  var minute = '0' + unixtimestamp.getMinutes();
  var second = '0' + unixtimestamp.getSeconds();
  return year + '-' + month.substring(month.length - 2, month.length) + '-' + date.substring(date.length - 2, date.length) + ' ' + hour.substring(hour.length - 2, hour.length) + ':' + minute.substring(minute.length - 2, minute.length) + ':' + second.substring(second.length - 2, second.length);
}
function isJSON(str) {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str);
      if ((0, _typeof2.default)(obj) === 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}
function customToFixed(number, decimalLength) {
  if (number === '' || number === null || number === 0 || isNaN(number)) {
    return 0;
  }
  var numArr = (number + '').split('.');
  if (numArr[1]) {
    return Number(numArr[0] + '.' + numArr[1].substring(0, decimalLength));
  }
  return number;
}

// 根据属性将数组分组
function buildDataKeyGroup(list, key) {
  var map = new Map();
  list.forEach(function (item, index, arr) {
    if (!map.has(item[key])) {
      map.set(item[key], arr.filter(function (a) {
        return a[key] === item[key];
      }));
    }
  });
  return Array.from(map).map(function (item) {
    return (0, _toConsumableArray2.default)(item[1]);
  });
}
function dataDeduplication(list, key) {
  var obj = {};
  return list.reduce(function (cur, next) {
    obj[next[key]] ? '' : obj[next[key]] = true && cur.push(next);
    return cur;
  }, []);
}