"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.auditList,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_id",
      label: _vm.$t("table.userId")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "status",
      label: _vm.$t("table.status")
    }
  }, [[_vm._v("Reject")]], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "remark",
      label: _vm.$t("table.commentsTeam")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "photo_url",
      label: _vm.$t("table.photo")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-image", {
          staticStyle: {
            width: "50px",
            height: "50px"
          },
          attrs: {
            src: row.photo_url,
            fit: "cover",
            "preview-src-list": [row.photo_url]
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.video")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("img", {
          directives: [{
            name: "video-viewer",
            rawName: "v-video-viewer",
            value: scope.row.video_url,
            expression: "scope.row.video_url"
          }],
          attrs: {
            height: "100px",
            src: "http://cdn-media-de.hala.icu/goods/video.png"
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.time")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeFilter")(row.updated_at, "yyyy-MM-dd hh:mm:ss")) + "\n      ")];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.auditTotal > 0,
      expression: "auditTotal>0"
    }],
    attrs: {
      total: _vm.auditTotal,
      page: _vm.auditListQuery.page,
      limit: _vm.auditListQuery.size
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.auditListQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.auditListQuery, "size", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;