"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _firstExamina = _interopRequireDefault(require("./components/firstExamina"));
var _reviewExamina = _interopRequireDefault(require("./components/reviewExamina"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    firstExamina: _firstExamina.default,
    reviewExamina: _reviewExamina.default
  },
  data: function data() {
    return {
      activeName: 'firstExamina'
    };
  }
};