"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [!_vm.roles.includes("channle") ? _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      filterable: "",
      placeholder: "所属部门",
      clearable: ""
    },
    on: {
      change: function change($event) {
        return _vm.getChannelCodeList();
      }
    },
    model: {
      value: _vm.listQuery.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "branch", $$v);
      },
      expression: "listQuery.branch"
    }
  }, _vm._l(_vm.branchList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "500px"
    },
    attrs: {
      filterable: "",
      multiple: "",
      placeholder: "渠道号",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.channelArr,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "channelArr", $$v);
      },
      expression: "listQuery.channelArr"
    }
  }, _vm._l(_vm.channelList, function (item) {
    return _c("el-option", {
      key: item.channel,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1), _vm._v(" "), _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetimerange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.$t("table.search")))]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-document"
    },
    on: {
      click: _vm.userExport
    }
  }, [_vm._v("导出数据")])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "created_at",
      label: _vm.$t("table.time")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeFilter")(row.created_at, "yyyy-MM-dd")) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "channel_no",
      label: "渠道号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_recharge_amount",
      label: "实充金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.user_recharge_amount)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "actual_settlement_amount",
      label: "实结金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.actual_settlement_amount)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "recharge_all_user_count",
      label: "充值总人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "ARPPU"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v("\n        $" + _vm._s(_vm._f("customToFixedFilter")(row.user_recharge_amount / 100 / row.recharge_all_user_count, 2)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_count",
      label: "新增用户数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_amount",
      label: "新用户实充"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.new_user_recharge_amount)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_settle_amount",
      label: "新用户实结"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.new_user_settle_amount)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_count",
      label: "新用户付费人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "新用户付费率"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm._v("\n        " + _vm._s(parseInt(row.new_user_recharge_count / row.new_user_count * 10000) / 100) + "%\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "新用户ARPPU"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_vm._v("\n        $" + _vm._s(_vm._f("customToFixedFilter")(row.new_user_recharge_amount / 100 / row.new_user_recharge_count, 2)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "新用户充值占比"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v("\n        " + _vm._s(parseInt(row.new_user_recharge_amount / row.user_recharge_amount * 10000) / 100) + "%\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "recharge_gold_user_count",
      label: "充值金币人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_recharge_gold",
      label: "金币实充金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.user_recharge_gold)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "recharge_vip_user_count",
      label: "充值vip人数"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "user_recharge_vip",
      label: "vip实充金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.user_recharge_vip)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "VIP充值占比"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_vm._v("\n        " + _vm._s(parseInt(row.user_recharge_vip / row.actual_settlement_amount * 10000) / 100) + "%\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_gold_count",
      label: "新用户充值金币人数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_vm._v("\n        " + _vm._s(row.new_user_recharge_gold_count) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_gold_amount",
      label: "新用户金币实充"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.new_user_recharge_gold_amount)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_vip_count",
      label: "新增用户充值VIP人数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var row = _ref15.row;
        return [_vm._v("\n        " + _vm._s(row.new_user_recharge_vip_count) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "new_user_recharge_vip_amount",
      label: "新用户VIP实充金额"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var row = _ref16.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("moneyFilter")(row.new_user_recharge_vip_amount)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "give_free_video_count",
      label: "赠送免费通话次数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var row = _ref17.row;
        return [_vm._v("\n        " + _vm._s(row.give_free_video_count) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "use_user_free_video_count",
      label: "使用免费通话人数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var row = _ref18.row;
        return [_vm._v("\n        " + _vm._s(row.use_user_free_video_count) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "use_free_video_count",
      label: "使用免费通话次数"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var row = _ref19.row;
        return [_vm._v("\n        " + _vm._s(row.use_free_video_count) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "免费通话使用率"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var row = _ref20.row;
        return [_vm._v("\n        " + _vm._s(parseInt(row.use_free_video_count / row.give_free_video_count * 10000) / 100) + "%\n      ")];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.size
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "size", $event);
      },
      pagination: _vm.getList
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "导出数据选择",
      visible: _vm.dialogVisible,
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "选择列"
    }
  }, [_c("el-checkbox", {
    attrs: {
      indeterminate: _vm.isIndeterminate
    },
    on: {
      change: _vm.handleCheckAllChange
    },
    model: {
      value: _vm.checkAll,
      callback: function callback($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("全选")]), _vm._v(" "), _c("el-checkbox-group", {
    on: {
      change: _vm.handleCheckedColumnChange
    },
    model: {
      value: _vm.columns,
      callback: function callback($$v) {
        _vm.columns = $$v;
      },
      expression: "columns"
    }
  }, _vm._l(_vm.xlsColumns, function (column) {
    return _c("el-checkbox", {
      key: column.value,
      attrs: {
        label: column.value
      }
    }, [_vm._v(_vm._s(column.label))]);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "选择区间"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "datetimerange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      clearable: ""
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取消")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.userExportList
    }
  }, [_vm._v("导出")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;