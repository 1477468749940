"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "customer-service",
    on: {
      click: _vm.switchBtn
    }
  }, [!_vm.visible ? _c("el-badge", {
    attrs: {
      hidden: _vm.totalUnreadCount <= 0,
      value: _vm.totalUnreadCount,
      max: 99
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.$t("customer.nimBtnTip"),
      placement: "top"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "customer-service"
    },
    on: {
      click: function click($event) {
        _vm.visible = !_vm.visible;
      }
    }
  })], 1)], 1) : _c("chat", {
    attrs: {
      visible: _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;