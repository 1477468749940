"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      EmojiList: this.$nim.getEmojiList()
    };
  },
  created: function created() {
    console.log(this.EmojiList);
  },
  methods: {
    checkEmoji: function checkEmoji(emoji) {
      this.$emit('checked', emoji);
    }
  }
};