"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "errPage-container"
  }, [_c("el-button", {
    staticClass: "pan-back-btn",
    attrs: {
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("\n    返回\n  ")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("h1", {
    staticClass: "text-jumbo text-ginormous"
  }, [_vm._v("\n        Oops!\n      ")]), _vm._v("\n      gif来源"), _c("a", {
    attrs: {
      href: "https://zh.airbnb.com/",
      target: "_blank"
    }
  }, [_vm._v("airbnb")]), _vm._v(" 页面\n      "), _c("h2", [_vm._v("你没有权限去该页面")]), _vm._v(" "), _c("h6", [_vm._v("如有不满请联系你领导")]), _vm._v(" "), _c("ul", {
    staticClass: "list-unstyled"
  }, [_c("li", [_vm._v("或者你可以去:")]), _vm._v(" "), _c("li", {
    staticClass: "link-type"
  }, [_c("router-link", {
    attrs: {
      to: "/dashboard"
    }
  }, [_vm._v("\n            回首页\n          ")])], 1), _vm._v(" "), _c("li", {
    staticClass: "link-type"
  }, [_c("a", {
    attrs: {
      href: "https://www.taobao.com/"
    }
  }, [_vm._v("随便看看")])]), _vm._v(" "), _c("li", [_c("a", {
    attrs: {
      href: "#"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.dialogVisible = true;
      }
    }
  }, [_vm._v("点我看图")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("img", {
    attrs: {
      src: _vm.errGif,
      width: "313",
      height: "428",
      alt: "Girl has dropped her ice cream."
    }
  })])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: "随便看"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    staticClass: "pan-img",
    attrs: {
      src: _vm.ewizardClap
    }
  })])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;