"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd",
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": _vm.$t("form.startDate"),
      "end-placeholder": _vm.$t("form.endDate")
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }), _vm._v(" "), _c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      placeholder: "最少耗时（秒）",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.query_time,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "query_time", $$v);
      },
      expression: "listQuery.query_time"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.$t("table.search")))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "start_time",
      label: "开始时间"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeFilter")(row.start_time, "yyyy-MM-dd hh:mm:ss")) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "query_time",
      label: "耗时"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "sql_text",
      label: "sql语句"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.size,
      "page-sizes": [10, 20, 30, 50, 100, 500, 1000]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "size", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;