"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.number.constructor");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      placeholder: _vm.$t("table.anchorId"),
      clearable: ""
    },
    model: {
      value: _vm.listQuery.anchor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "anchor_id", $$v);
      },
      expression: "listQuery.anchor_id"
    }
  }), _vm._v(" "), _c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      placeholder: _vm.$t("table.countryCode"),
      clearable: ""
    },
    model: {
      value: _vm.listQuery.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "country_code", $$v);
      },
      expression: "listQuery.country_code"
    }
  }), _vm._v(" "), _vm.pcshow ? _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetimerange",
      "range-separator": "-",
      "start-placeholder": "Start Time",
      "end-placeholder": "End Time",
      "popper-class": "pick-time-mobile"
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.pcshow ? _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetime",
      placeholder: "Start Time",
      clearable: ""
    },
    model: {
      value: _vm.startTime,
      callback: function callback($$v) {
        _vm.startTime = $$v;
      },
      expression: "startTime"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.pcshow ? _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetime",
      placeholder: "End Time",
      clearable: ""
    },
    model: {
      value: _vm.endTime,
      callback: function callback($$v) {
        _vm.endTime = $$v;
      },
      expression: "endTime"
    }
  }) : _vm._e(), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.$t("table.search")))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "date",
      formatter: _vm.dateFormat,
      label: _vm.$t("table.date")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "anchor_id",
      label: _vm.$t("table.anchorId")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "country_code",
      label: _vm.$t("table.countryCode")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "total_calls",
      label: _vm.$t("table.totalCalls")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "number_calls_the_host",
      label: _vm.$t("table.numberCallsTheHost")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "number_calls_called",
      label: _vm.$t("table.numberCallsCalled")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "unlock_revenue_gold",
      label: _vm.$t("table.unlockRevenueGold")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "gift_gold",
      label: _vm.$t("table.giftsIncome")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("goldFilter")(scope.row.gift_gold)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "chat_gold",
      label: _vm.$t("table.messageCoins")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("goldFilter")(scope.row.chat_gold)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "video_gold",
      label: _vm.$t("table.videoCalls")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("goldFilter")(scope.row.video_gold)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "mission_gold",
      label: _vm.$t("table.taskCoins")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("goldFilter")(scope.row.mission_gold)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "total_gold",
      label: _vm.$t("table.totalIncome")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("goldFilter")(scope.row.total_gold)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "video_time",
      label: _vm.$t("table.videoTime")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "all_called_video",
      label: _vm.$t("table.incomingCalls")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "success_called_video",
      label: _vm.$t("table.connectedIncomingCalls")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "rate",
      label: _vm.$t("table.callConnectRate")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("rateFilter")(Number(scope.row.number_calls_called) / Number(scope.row.all_called_video) || 0)) + "%\n      ")];
      }
    }])
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.size,
      "page-sizes": [10, 30, 50, 100, 500]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "size", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;