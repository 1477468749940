"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChannelApps = getChannelApps;
exports.getHomeDashboard = getHomeDashboard;
exports.getPushFlow = getPushFlow;
exports.getRealData = getRealData;
exports.getRealInfo = getRealInfo;
exports.getRealList = getRealList;
exports.getRealUserInfo = getRealUserInfo;
exports.getRealUserList = getRealUserList;
exports.getZegoToken = getZegoToken;
exports.googleVerify = googleVerify;
exports.reviewVideoList = reviewVideoList;
exports.toopenGoogleAuthenticator = toopenGoogleAuthenticator;
exports.uploadSendMedia = uploadSendMedia;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var _auth = require("@/utils/auth");
/**
 * 获取首页仪表盘数据
 * @param {*} params
 */
function getHomeDashboard(params) {
  return (0, _request.default)({
    url: '/dashboard',
    method: 'GET',
    params: params
  });
}

/**
 * 开启谷歌身份验证器
 * @param {*} params
 */
function toopenGoogleAuthenticator(params) {
  return (0, _request.default)({
    url: '/toopen/google/authenticator',
    method: 'GET',
    params: params
  });
}

/**
 * 谷歌身份验证绑定
 * @param {*} data
 */
function googleVerify(data) {
  return (0, _request.default)({
    url: '/toopen/verify',
    method: 'POST',
    data: data
  });
}

/**
 * 获取实时主播数据
 * @param {*} data
 */
function getRealData(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/anchorRealTime/getRealData"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
// export function getRealData(data) {
//   return request({
//     url: '/getRealData',
//     method: 'POST',
//     data
//   })
// }

/**
 * 获取实时主播列表
 * @param {*} data
 */
// export function getRealList(data) {
//   return axios({
//     url: `${process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN}/anchorRealTime/getRealList`,
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'token': getToken()
//     },
//     data
//   })
// }
function getRealList(data) {
  return (0, _request.default)({
    url: '/getRealList',
    method: 'POST',
    data: data
  });
}

/**
 * 获取实时主播详细信息
 * @param {*} data
 */
function getRealInfo(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/anchorRealTime/getRealInfo"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 获取用户实时列表
 */
function getRealUserList(data) {
  return (0, _request.default)({
    url: '/getRealUserList',
    method: 'POST',
    data: data
  });
}

/**
 * 获取用户实时详情
 */
function getRealUserInfo(data) {
  return (0, _request.default)({
    url: '/getRealUserInfo',
    method: 'POST',
    data: data
  });
}

/**
 * 获取渠道应用列表
 * @param {*} data
 */
function getChannelApps(data) {
  return (0, _request.default)({
    url: '/getChannelApps',
    method: 'POST',
    data: data
  });
}

// export function getRealInfo(data) {
//   return request({
//     url: '/getRealInfo',
//     method: 'POST',
//     data
//   })
// }

/**
 * 获取主播推流列表
 * @param {*} data
 */
function getPushFlow(data) {
  return (0, _request.default)({
    url: '/getPushFlow',
    method: 'POST',
    data: data
  });
}

/**
 * 上传图片
 */
function uploadSendMedia(data) {
  return (0, _request.default)({
    url: '/file/upload',
    method: 'POST',
    data: data
  });
}

/**
 * 获取即构鉴权token
 */
function getZegoToken(data) {
  return (0, _request.default)({
    url: '/getZegoToken',
    method: 'POST',
    data: data
  });
}

/**
 * 获取视频列表
 */
function reviewVideoList(params) {
  return (0, _request.default)({
    url: '/reviewVideoList',
    method: 'GET',
    params: params
  });
}