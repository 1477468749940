"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.$t("navbar.lock"),
      placement: "bottom"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "lock"
    },
    on: {
      click: _vm.handleLock
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.$t("navbar.setLockPassword"),
      visible: _vm.lockBox,
      width: "30%",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.lockBox = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-width": "80px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("form.lockPassword"),
      prop: "passwd",
      rules: [{
        required: true,
        message: _vm.$t("form.lockPasswordNotEmpty")
      }]
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "".concat(_vm.$t("form.pleaseEnter")).concat(_vm.$t("form.lockPassword"))
    },
    model: {
      value: _vm.form.passwd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "passwd", $$v);
      },
      expression: "form.passwd"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSetLock
    }
  }, [_vm._v(_vm._s(_vm.$t("form.confirm")))])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;