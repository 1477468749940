"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoViewer = _interopRequireDefault(require("./video-viewer"));
var install = function install(Vue) {
  Vue.directive('video-viewer', _videoViewer.default);
};
if (window.Vue) {
  window.VideoViewer = _videoViewer.default;
  Vue.use(install); // eslint-disable-line
}
_videoViewer.default.install = install;
var _default = exports.default = _videoViewer.default;