"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.areaList = areaList;
exports.auditList = auditList;
exports.bannerList = bannerList;
exports.batchList = batchList;
exports.bindWorks = bindWorks;
exports.brandList = brandList;
exports.changeDealerStatus = changeDealerStatus;
exports.channelAccount = channelAccount;
exports.channelCodeList = channelCodeList;
exports.clearAppNameData = clearAppNameData;
exports.countryList = countryList;
exports.dealerBillsList = dealerBillsList;
exports.dealerExport = dealerExport;
exports.dealerList = dealerList;
exports.dealerRechargeAudit = dealerRechargeAudit;
exports.dealerRechargeList = dealerRechargeList;
exports.dealerRechargeSubmit = dealerRechargeSubmit;
exports.delAppChannel = delAppChannel;
exports.delAudit = delAudit;
exports.delBrand = delBrand;
exports.delLevelRule = delLevelRule;
exports.delMsg = delMsg;
exports.delRedisAudit = delRedisAudit;
exports.delSticker = delSticker;
exports.deleteChannel = deleteChannel;
exports.deleteOpenScreeAd = deleteOpenScreeAd;
exports.deletePaychannel = deletePaychannel;
exports.deleteProduct = deleteProduct;
exports.discourseList = discourseList;
exports.editAppChannel = editAppChannel;
exports.editAudit = editAudit;
exports.editBanner = editBanner;
exports.editBrand = editBrand;
exports.editChannel = editChannel;
exports.editCountry = editCountry;
exports.editDealer = editDealer;
exports.editDiscourse = editDiscourse;
exports.editFemaleHead = editFemaleHead;
exports.editGift = editGift;
exports.editGood = editGood;
exports.editLevelRule = editLevelRule;
exports.editMaleHead = editMaleHead;
exports.editMarketPop = editMarketPop;
exports.editMsg = editMsg;
exports.editOpenScreeAd = editOpenScreeAd;
exports.editPaychannel = editPaychannel;
exports.editPictureFrame = editPictureFrame;
exports.editPoint = editPoint;
exports.editProduct = editProduct;
exports.editRank = editRank;
exports.editSignConfig = editSignConfig;
exports.editSticker = editSticker;
exports.editUserRank = editUserRank;
exports.editVersion = editVersion;
exports.editWords = editWords;
exports.femaleHead = femaleHead;
exports.getDictList = getDictList;
exports.getOpenScreeAdList = getOpenScreeAdList;
exports.getPictureFrameList = getPictureFrameList;
exports.getTransferUserList = getTransferUserList;
exports.giftList = giftList;
exports.goodList = goodList;
exports.induceEdit = induceEdit;
exports.induceList = induceList;
exports.induceStatus = induceStatus;
exports.induceWordEdit = induceWordEdit;
exports.induceWordList = induceWordList;
exports.levelRuleList = levelRuleList;
exports.maleHead = maleHead;
exports.marketPopList = marketPopList;
exports.msgList = msgList;
exports.payChannelList = payChannelList;
exports.pointList = pointList;
exports.productList = productList;
exports.promoterEdit = promoterEdit;
exports.promoterList = promoterList;
exports.rankList = rankList;
exports.rankUserList = rankUserList;
exports.saveDealer = saveDealer;
exports.searchRedisAudit = searchRedisAudit;
exports.sendTransferUser = sendTransferUser;
exports.signConfigList = signConfigList;
exports.statusPaychannel = statusPaychannel;
exports.stickerList = stickerList;
exports.updateBannerStatus = updateBannerStatus;
exports.updateChannelAccountStatus = updateChannelAccountStatus;
exports.updateDiscourseStatus = updateDiscourseStatus;
exports.updateGiftStatus = updateGiftStatus;
exports.updateOpenScreeAdStatus = updateOpenScreeAdStatus;
exports.versionList = versionList;
exports.wordsLibraryDel = wordsLibraryDel;
exports.wordsLibraryEdit = wordsLibraryEdit;
exports.wordsLibraryList = wordsLibraryList;
exports.wordsList = wordsList;
var _axios = _interopRequireDefault(require("axios"));
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 获取产品列表
 * @param {*} params
 */
function productList(params) {
  return (0, _request.default)({
    url: '/operation/product/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增产品
 * @param {*} data
 */
function editProduct(data) {
  return (0, _request.default)({
    url: '/operation/product/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除产品
 * @param {*} data
 */
function deleteProduct(data) {
  return (0, _request.default)({
    url: '/operation/product/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取banner列表
 * @param {*} params
 */
function bannerList(params) {
  return (0, _request.default)({
    url: '/operation/banner/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增轮播
 * @param {*} data
 */
function editBanner(data) {
  return (0, _request.default)({
    url: '/operation/banner/edit',
    method: 'post',
    data: data
  });
}

/**
 * 修改轮播状态
 * @param {*} data
 */
function updateBannerStatus(data) {
  return (0, _request.default)({
    url: '/operation/banner/updateStatus',
    method: 'post',
    data: data
  });
}

/**
 * 获取礼物列表
 * @param {*} params
 */
function goodList(params) {
  return (0, _request.default)({
    url: '/operation/good/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增礼物
 * @param {*} data
 */
function editGood(data) {
  return (0, _request.default)({
    url: '/operation/good/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取应用列表
 * @param {*} params
 */
function brandList(params) {
  return (0, _request.default)({
    url: '/operation/brand/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增应用
 * @param {*} data
 */
function editBrand(data) {
  return (0, _request.default)({
    url: '/operation/brand/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除应用
 * @param {*} data
 */
function delBrand(data) {
  return (0, _request.default)({
    url: '/operation/brand/delete',
    method: 'post',
    data: data
  });
}

/**
 * 编辑/新增渠道包编号
 * @param {*} data
 */
function editAppChannel(data) {
  return (0, _request.default)({
    url: '/operation/appChannel/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除渠道包编号
 * @param {*} data
 */
function delAppChannel(data) {
  return (0, _request.default)({
    url: '/operation/appChannel/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取批次列表
 * @param {*} params
 */
function batchList(params) {
  return (0, _request.default)({
    url: '/operation/batch/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取渠道列表
 * @param {*} params
 */
function channelCodeList(params) {
  return (0, _request.default)({
    url: '/operation/channelCode/list',
    method: 'get',
    params: params
  });
}

// 获取礼物列表
function giftList(params) {
  return (0, _request.default)({
    url: '/operation/gift/list',
    method: 'GET',
    params: params
  });
}

// 更新产品礼物
function updateGiftStatus(data) {
  return (0, _request.default)({
    url: '/operation/gift/updateStatus',
    method: 'post',
    data: data
  });
}

// 新增/修改礼物
function editGift(data) {
  return (0, _request.default)({
    url: '/operation/gift/edit',
    method: 'post',
    data: data
  });
}

// 获取打招呼列表
function msgList(params) {
  return (0, _request.default)({
    url: '/operation/msg/list',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑/新增打招呼内容
 * @param {*} data
 */
function editMsg(data) {
  return (0, _request.default)({
    url: '/operation/msg/edit',
    method: 'post',
    data: data
  });
}

// 获取版本列表
function versionList(params) {
  return (0, _request.default)({
    url: '/operation/version/list',
    method: 'GET',
    params: params
  });
}

/**
 * 编辑/新增应用
 * @param {*} data
 */
function editVersion(data) {
  return (0, _request.default)({
    url: '/operation/version/edit',
    method: 'post',
    data: data
  });
}

// 获取审核版本
function auditList(params) {
  return (0, _request.default)({
    url: '/operation/audit/list',
    method: 'GET',
    params: params
  });
}

/**
 * 新增审核版本
 * @param {*} data
 */
function editAudit(data) {
  return (0, _request.default)({
    url: '/operation/audit/edit',
    method: 'post',
    data: data
  });
}

/**
 * 查询审核版本redis
 * @param {*} data
 */
function searchRedisAudit(data) {
  return (0, _request.default)({
    url: '/operation/search/redis/audit',
    method: 'post',
    data: data
  });
}

/**
 * 删除redis缓存审核版本
 * @param {*} data
 */
function delRedisAudit(data) {
  return (0, _request.default)({
    url: '/operation/del/redis/audit',
    method: 'post',
    data: data
  });
}

// 获取推广人列表
function promoterList(params) {
  return (0, _request.default)({
    url: '/operation/promoter/list',
    method: 'GET',
    params: params
  });
}

// 排行榜-主播
function rankList(params) {
  return (0, _request.default)({
    url: '/operation/rank/list',
    method: 'GET',
    params: params
  });
}

/**
 * 更新主播排行榜
 * @param {*} data
 */
function editRank(data) {
  return (0, _request.default)({
    url: '/operation/rank/edit',
    method: 'post',
    data: data
  });
}

// 排行榜-用户
function rankUserList(params) {
  return (0, _request.default)({
    url: '/operation/rankUser/list',
    method: 'GET',
    params: params
  });
}

/**
 * 更新用户排行榜
 * @param {*} data
 */
function editUserRank(data) {
  return (0, _request.default)({
    url: '/operation/rankUser/edit',
    method: 'post',
    data: data
  });
}

/**
 * 新增编辑推广人
 * @param {*} data
 */
function promoterEdit(data) {
  return (0, _request.default)({
    url: '/operation/promoter/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除审核版本
 * @param {*} data
 */
function delAudit(data) {
  return (0, _request.default)({
    url: '/operation/audit/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取配置列表
 * @param {*} params
 */
function getDictList(params) {
  return (0, _request.default)({
    url: '/operation/getDictList',
    method: 'get',
    params: params
  });
}

/**
 * 获取支付通道列表
 * @param {*} params
 */
function payChannelList(params) {
  return (0, _request.default)({
    url: '/operation/payChannelList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增支付通道
 * @param {*} data
 */
function editPaychannel(data) {
  return (0, _request.default)({
    url: '/operation/paychannel/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除支付通道
 * @param {*} data
 */
function deletePaychannel(data) {
  return (0, _request.default)({
    url: '/operation/deletePaychannel',
    method: 'post',
    data: data
  });
}

/**
 * 更改支付通道状态
 * @param {*} data
 */
function statusPaychannel(data) {
  return (0, _request.default)({
    url: '/operation/statusPaychannel',
    method: 'post',
    data: data
  });
}
/**
 * 获取国家地区列表
 * @param {*} params
 */
function countryList(params) {
  return (0, _request.default)({
    url: '/operation/country/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑国家地区配置
 * @param {*} data
 */
function editCountry(data) {
  return (0, _request.default)({
    url: '/operation/country/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取地区列表
 */
function areaList(params) {
  return (0, _request.default)({
    url: '/operation/area/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取渠道账号列表
 * @param {*} params
 */
function channelAccount(params) {
  return (0, _request.default)({
    url: '/operation/channelAccount/list',
    method: 'get',
    params: params
  });
}

/**
 * 修改渠道账号
 * @param {*} data
 */
function editChannel(data) {
  return (0, _request.default)({
    url: '/operation/channelAccount/edit',
    method: 'post',
    data: data
  });
}

/**
 * 修改策略状态
 * @param {*} data
 */
function updateChannelAccountStatus(data) {
  return (0, _request.default)({
    url: '/operation/channelAccount/updateStatus',
    method: 'post',
    data: data
  });
}

/**
 * 删除招呼
 * @param {*} data
 */
function delMsg(data) {
  return (0, _request.default)({
    url: '/operation/msg/delete',
    method: 'post',
    data: data
  });
}

/**
 * 删除渠道账号
 * @param {*} data
 */
function deleteChannel(data) {
  return (0, _request.default)({
    url: '/operation/channelAccount/delete',
    method: 'post',
    data: data
  });
}

/**
 * 获取默认男头像列表
 * @param {*} params
 */
function maleHead(params) {
  return (0, _request.default)({
    url: '/operation/maleHead/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取默认女头像列表
 * @param {*} params
 */
function femaleHead(params) {
  return (0, _request.default)({
    url: '/operation/femaleHead/list',
    method: 'get',
    params: params
  });
}

/**
 * 添加默认男头
 * @param {*} data
 */
function editMaleHead(data) {
  return (0, _request.default)({
    url: '/operation/maleHead/edit',
    method: 'post',
    data: data
  });
}

/**
 * 添加默认女头
 * @param {*} data
 */
function editFemaleHead(data) {
  return (0, _request.default)({
    url: '/operation/femaleHead/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取开屏页列表
 * @param {*} params
 */
function getOpenScreeAdList(params) {
  return (0, _request.default)({
    url: '/operation/open/scree/ad/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增开屏页
 * @param {*} data
 */
function editOpenScreeAd(data) {
  return (0, _request.default)({
    url: '/operation/open/scree/ad/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除开屏页
 * @param {*} data
 */
function deleteOpenScreeAd(data) {
  return (0, _request.default)({
    url: '/operation/open/scree/ad/delete',
    method: 'post',
    data: data
  });
}

/**
 * 更新状态开屏页
 * @param {*} data
 */
function updateOpenScreeAdStatus(data) {
  return (0, _request.default)({
    url: '/operation/open/scree/ad/updateStatus',
    method: 'post',
    data: data
  });
}

/**
 * 获取策略列表
 * @param {*} params
 */
function discourseList(params) {
  return (0, _request.default)({
    url: '/operation/discourse/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增策略
 * @param {*} data
 */
function editDiscourse(data) {
  return (0, _request.default)({
    url: '/operation/discourse/edit',
    method: 'post',
    data: data
  });
}

/**
 * 修改策略状态
 * @param {*} data
 */
function updateDiscourseStatus(data) {
  return (0, _request.default)({
    url: '/operation/discourse/updateStatus',
    method: 'post',
    data: data
  });
}

/**
 * 策略绑定假主播
 * @param {*} data
 */
function bindWorks(data) {
  return (0, _request.default)({
    url: '/operation/bind/works',
    method: 'post',
    data: data
  });
}

/**
 * 获取话术列表
 * @param {*} params
 */
function wordsList(params) {
  return (0, _request.default)({
    url: '/operation/words/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增话术
 * @param {*} data
 */
function editWords(data) {
  return (0, _request.default)({
    url: '/operation/words/edit',
    method: 'post',
    data: data
  });
}

/**
 * 清理应用审核数据
 * @param {*} data
 */
function clearAppNameData(data) {
  return (0, _request.default)({
    url: '/operation/clearAppNameData',
    method: 'post',
    data: data
  });
}

/**
 * 获取特权头像列表
 * @param {*} params
 */
function getPictureFrameList(params) {
  return (0, _request.default)({
    url: '/operation/getPictureFrameList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增特权头像列表
 * @param {*} data
 */
function editPictureFrame(data) {
  return (0, _request.default)({
    url: '/operation/editPictureFrame',
    method: 'post',
    data: data
  });
}

/**
 * 获取签到列表
 * @param {*} params
 */
function signConfigList(params) {
  return (0, _request.default)({
    url: '/operation/signConfig/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增签到
 * @param {*} data
 */
function editSignConfig(data) {
  return (0, _request.default)({
    url: '/operation/signConfig/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取诱导组列表
 * @param {*} params
 */
function induceList(params) {
  return (0, _request.default)({
    url: '/operation/induceList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增诱导组
 * @param {*} data
 */
function induceEdit(data) {
  return (0, _request.default)({
    url: '/operation/induceEdit',
    method: 'post',
    data: data
  });
}

/**
 * 修改诱导组状态
 * @param {*} data
 */
function induceStatus(data) {
  return (0, _request.default)({
    url: '/operation/induceStatus',
    method: 'post',
    data: data
  });
}

/**
 * 获取诱导组文案列表
 * @param {*} params
 */
function induceWordList(params) {
  return (0, _request.default)({
    url: '/operation/induceWordList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增诱导组文案
 * @param {*} data
 */
function induceWordEdit(data) {
  return (0, _request.default)({
    url: '/operation/induceWordEdit',
    method: 'post',
    data: data
  });
}

/**
 * 获取贴纸列表
 * @param {*} params
 */
function stickerList(params) {
  return (0, _request.default)({
    url: '/operation/stickerList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增贴纸
 * @param {*} data
 */
function editSticker(data) {
  return (0, _request.default)({
    url: '/operation/editSticker',
    method: 'post',
    data: data
  });
}

/**
 * 删除贴纸
 * @param {*} data
 */
function delSticker(data) {
  return (0, _request.default)({
    url: '/operation/delSticker',
    method: 'post',
    data: data
  });
}

/**
 * 召回列表
 * @param {*} params
 */
function getTransferUserList(params) {
  return (0, _request.default)({
    url: '/operation/getTransferUserList',
    method: 'get',
    params: params
  });
}

/**
 * 发送用户召回邮件
 * @param {*} data
 */
function sendTransferUser(data) {
  return (0, _request.default)({
    url: '/operation/sendTransferUser',
    method: 'post',
    data: data
  });
}

/**
 * 获取文案库列表
 * @param {*} params
 */
function wordsLibraryList(params) {
  return (0, _request.default)({
    url: '/operation/wordsLibraryList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增文案库
 * @param {*} data
 */
function wordsLibraryEdit(data) {
  return (0, _request.default)({
    url: '/operation/wordsLibraryEdit',
    method: 'post',
    data: data
  });
}

/**
 * 删除文案库
 * @param {*} data
 */
function wordsLibraryDel(data) {
  return (0, _request.default)({
    url: '/operation/wordsLibraryDel',
    method: 'post',
    data: data
  });
}

/**
 * 主播评分规则列表
 */
function levelRuleList(params) {
  return (0, _request.default)({
    url: '/operation/levelRuleList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑主播评分规则
 * @param {*} data
 */
function editLevelRule(data) {
  return (0, _request.default)({
    url: '/operation/editLevelRule',
    method: 'post',
    data: data
  });
}

/**
 * 删除主播评分规则
 * @param {*} data
 */
function delLevelRule(data) {
  return (0, _request.default)({
    url: '/operation/delLevelRule',
    method: 'post',
    data: data
  });
}

/**
 * 获取引导列表
 * @param {*} params
 */
function pointList(params) {
  return (0, _request.default)({
    url: '/operation/pointList',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增引导
 * @param {*} data
 */
function editPoint(data) {
  return (0, _request.default)({
    url: '/operation/editPoint',
    method: 'post',
    data: data
  });
}

/**
 * 营销位弹窗列表
 */
function marketPopList(params) {
  return (0, _request.default)({
    url: '/operation/marketPopList',
    method: 'get',
    params: params
  });
}
/**
 * 编辑营销位弹窗
 */
function editMarketPop(data) {
  return (0, _request.default)({
    url: '/operation/editMarketPop',
    method: 'post',
    data: data
  });
}

/**
 * 币商列表
 */
function dealerList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/coinerList"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
/**
 * 编辑币商
 */
function editDealer(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/createUser"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
/**
 * 更改币商状态
 */
function changeDealerStatus(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/close"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}
/**
 * 完善币商信息
 */
function saveDealer(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/update"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 币商充值列表
 */
function dealerRechargeList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/rechargeList"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 币商充值审核接口
 */
function dealerRechargeAudit(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/coin/handle"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 币商充值提交接口
 */
function dealerRechargeSubmit(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/coin/submit"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 币商流水列表
 */
function dealerBillsList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/dealer/sellList"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 币商数据导出
 */
function dealerExport(params) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/excel/export/data"),
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    params: params
  });
}