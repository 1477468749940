"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AppMain',
  data: function data() {
    return {
      dialogResourcesVisible: false
    };
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  methods: {
    showDialogResources: function showDialogResources() {
      this.dialogResourcesVisible = true;
    },
    checkResources: function checkResources() {
      this.$refs.childerView.callBackResources('http://baidu.com');
    }
  }
};