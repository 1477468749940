"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _settings = _interopRequireDefault(require("@/settings"));
var _lang = _interopRequireDefault(require("@/lang"));
var title = _settings.default.title || '视频项目后台管理系统';
function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));
  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));
    return (process.env.VUE_APP_ENV !== 'prod' && '测试 - ' || '') + "".concat(pageName, " - ").concat(title);
  }
  return "".concat(title);
}