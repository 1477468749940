"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.number.constructor");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      placeholder: "主播ID",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.anchor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "anchor_id", $$v);
      },
      expression: "listQuery.anchor_id"
    }
  }), _vm._v(" "), _c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      placeholder: "用户ID",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.user_id,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "user_id", $$v);
      },
      expression: "listQuery.user_id"
    }
  }), _vm._v(" "), _c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      placeholder: "通话记录ID",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.call_record_id,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "call_record_id", $$v);
      },
      expression: "listQuery.call_record_id"
    }
  }), _vm._v(" "), _c("el-date-picker", {
    staticClass: "filter-item",
    staticStyle: {
      width: "460px"
    },
    attrs: {
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": _vm.$t("form.startDate"),
      "end-placeholder": _vm.$t("form.endDate"),
      clearable: ""
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.$t("table.search")))]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.setPlaybackRate
    }
  }, [_vm._v("全局倍率：" + _vm._s(_vm.playbackRate))])], 1), _vm._v(" "), _vm.list.length ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "videoContent"
  }, _vm._l(_vm.list, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "videoItem"
    }, [_c("imageToVideo", {
      attrs: {
        "video-width": "200px",
        "video-height": "260px",
        "video-url": _vm._f("cdnUrlFilter")(item.video_url),
        "pic-url": _vm._f("cdnUrlFilter")(item.first_frame),
        "playback-rate": _vm.playbackRate
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "videoInfo"
    }, [_c("div", [_c("span", [_vm._v("用户ID:")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.user_id))])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("主播ID:")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.anchor_id))])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("通话记录ID:")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.call_record_id))])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("视频时长:")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(Number(item.duration)) + "s")])]), _vm._v(" "), _c("div", [_c("span", [_vm._v("录制时长:")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm._f("dateTimeFilter")(item.created_at, "yyyy-MM-dd hh:mm:ss")))])])])], 1);
  }), 0) : _c("el-empty", {
    attrs: {
      description: "暂无数据"
    }
  }), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.size,
      "page-sizes": [20, 30, 50]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "size", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;