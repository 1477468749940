"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _vue = _interopRequireDefault(require("vue"));
//  v-dialogDrag: 弹窗拖拽属性
_vue.default.directive('dialogDrag', {
  bind: function bind(el, binding, vnode, oldVnode) {
    // 弹框可拉伸最小宽高
    var minWidth = 400;
    var minHeight = 300;
    // 初始非全屏
    var isFullScreen = false;
    // 当前宽高
    var nowWidth = 0;
    // 当前顶部高度
    var nowMarginTop = 0;
    // 获取弹框头部（这部分可双击全屏)
    var dialogHeaderEl = el.querySelector('.title');
    // 获取居中按钮
    var centerBtn = el.querySelector('.centerBtn');
    // 弹窗
    var dragDom = el.querySelector('.chat');
    var chatContainer = el.querySelector('.chat-container');
    //  全屏按钮
    dialogHeaderEl.insertAdjacentHTML('beforeEnd', '<div class="bigbtn"><div class="changeBig"></div><div class="changeSmall"></div></div>');
    var bigBtn = el.querySelector('.bigbtn');
    var changeBig = el.querySelector('.changeBig');
    var changeSmall = el.querySelector('.changeSmall');
    // 给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog
    dragDom.style.overflow = 'auto';
    // 清除选择头部文字效果
    // dialogHeaderEl.onselectstart = new Function("return false")
    // 头部加上可拖动cursor
    dialogHeaderEl.style.cursor = 'move';
    //  获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null)
    var sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    var moveDown = function moveDown(e) {
      //  鼠标按下，计算当前元素距离可视区的距离
      var disX = e.clientX - dialogHeaderEl.offsetLeft;
      var disY = e.clientY - dialogHeaderEl.offsetTop;
      //  获取到的值带px 正则匹配替换
      var styL, styT;
      //  注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      }
      document.onmousemove = function (e) {
        //  通过事件委托，计算移动的距离
        var l = e.clientX - disX;
        var t = e.clientY - disY;
        var left = l + styL;
        var top = t + styT < 0 ? 0 : t + styT;
        //  移动当前元素
        dragDom.style.left = "".concat(left, "px");
        dragDom.style.top = "".concat(top, "px");

        // 将此时的位置传出去

        // binding.value({x:e.pageX,y:e.pageY})
      };
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
        chatContainer.style.userSelect = 'text';
      };
    };
    dialogHeaderEl.onmousedown = moveDown;

    // 双击头部全屏效果
    bigBtn.onclick = function (e) {
      if (isFullScreen === false) {
        changeBig.style.display = 'none';
        changeSmall.style.display = 'block';
        nowWidth = dragDom.clientWidth;
        nowMarginTop = dragDom.style.marginTop;
        dragDom.style.left = 0;
        dragDom.style.top = 0;
        dragDom.style.height = '100VH';
        dragDom.style.width = '100VW';
        dragDom.style.marginTop = 0;
        isFullScreen = true;
        dialogHeaderEl.style.cursor = 'initial';
        dialogHeaderEl.onmousedown = null;
      } else {
        changeBig.style.display = 'block';
        changeSmall.style.display = 'none';
        dragDom.style.height = 'auto';
        dragDom.style.width = nowWidth + 'px';
        dragDom.style.marginTop = nowMarginTop;
        isFullScreen = false;
        dialogHeaderEl.style.cursor = 'move';
        dialogHeaderEl.onmousedown = moveDown;
      }
    };

    // 点击居中按钮让弹框居中
    centerBtn.onclick = function () {
      dragDom.style.marginTop = '0px';
      dragDom.style.left = (document.body.clientWidth - dragDom.offsetWidth) / 2 + 'px';
      dragDom.style.top = (document.body.clientHeight - dragDom.offsetHeight) / 2 + 'px';
    };
    dragDom.onmousemove = function (e) {
      //  console.log(dialogHeaderEl,dragDom.querySelector('.el-dialog__body'), 123)
      if (e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10 || dragDom.offsetLeft + 10 > e.clientX) {
        dragDom.style.cursor = 'w-resize';
      } else if (el.scrollTop + e.clientY > dragDom.offsetTop + dragDom.clientHeight - 10) {
        dragDom.style.cursor = 's-resize';
      } else {
        dragDom.style.cursor = 'default';
        dragDom.onmousedown = null;
      }
      chatContainer.style.userSelect = 'text';
      dragDom.onmousedown = function (e) {
        var clientX = e.clientX;
        var clientY = e.clientY;
        var elW = dragDom.clientWidth;
        var elH = dragDom.clientHeight;
        var EloffsetLeft = dragDom.offsetLeft;
        var EloffsetTop = dragDom.offsetTop;
        dragDom.style.userSelect = 'none';
        chatContainer.style.userSelect = 'text';
        var ELscrollTop = el.scrollTop;

        // 判断点击的位置是不是为头部

        if (clientX > EloffsetLeft && clientX < EloffsetLeft + elW && clientY > EloffsetTop && clientY < EloffsetTop + 100) {
          // 如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown
        } else {
          document.onmousemove = function (e) {
            e.preventDefault(); //  移动时禁用默认事件
            // 左侧鼠标拖拽位置
            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
              // 往左拖拽
              if (clientX > e.clientX) {
                dragDom.style.width = elW + (clientX - e.clientX) * 2 + 'px';
              }
              // 往右拖拽
              if (clientX < e.clientX) {
                if (dragDom.clientWidth > minWidth) {
                  dragDom.style.width = elW - (e.clientX - clientX) * 2 + 'px';
                }
              }
            }

            // 右侧鼠标拖拽位置
            if (clientX > EloffsetLeft + elW - 10 && clientX < EloffsetLeft + elW) {
              // 往左拖拽
              if (clientX > e.clientX) {
                if (dragDom.clientWidth > minWidth) {
                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';
                }
              }

              // 往右拖拽
              if (clientX < e.clientX) {
                dragDom.style.width = elW + (e.clientX - clientX) * 2 + 'px';
              }
            }

            // 底部鼠标拖拽位置
            if (ELscrollTop + clientY > EloffsetTop + elH - 20 && ELscrollTop + clientY < EloffsetTop + elH) {
              // 往上拖拽
              if (clientY > e.clientY) {
                if (dragDom.clientHeight > minHeight) {
                  dragDom.style.height = elH - (clientY - e.clientY) * 2 + 'px';
                }
              }

              // 往下拖拽
              if (clientY < e.clientY) {
                dragDom.style.height = elH + (e.clientY - clientY) * 2 + 'px';
              }
            }
          };

          // 拉伸结束
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
            chatContainer.style.userSelect = 'text';
          };
        }
      };
    };
  }
});