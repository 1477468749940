"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _sockjsClient = _interopRequireDefault(require("sockjs-client"));
var _stompjs = _interopRequireDefault(require("stompjs"));
_vue.default.use(_vuex.default);
var _default = exports.default = new _vuex.default.Store({
  state: {
    url: process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN + process.env.VUE_APP_STOMP_CLIENT_PATH,
    initSocketDate: null,
    checkInterval: null,
    // 断线重连时 检测连接是否建立成功
    websocket: null,
    stompClient: null,
    listenerList: [] // 监听器列表，断线重连时 用于重新注册监听
  },
  getters: {
    stompClient: function stompClient(state) {
      return function () {
        return state.stompClient;
      };
    }
  },
  mutations: {
    WEBSOCKET_INIT: function WEBSOCKET_INIT(state) {
      var _this2 = this;
      if (state.stompClient == null || !state.stompClient.connected) {
        if (state.stompClient != null && state.websocket.readyState === _sockjsClient.default.OPEN) {
          state.stompClient.disconnect(function () {
            _this2.commit('WEBSOCKET_CONNECT');
          });
        } else if (state.stompClient != null && state.websocket.readyState === _sockjsClient.default.CONNECTING) {
          console.log('连接正在建立');
          return;
        } else {
          this.commit('WEBSOCKET_CONNECT');
        }
        if (!state.checkInterval) {
          var interval = setInterval(function () {
            console.log('检测连接：' + state.websocket.readyState);
            if (state.stompClient != null && state.stompClient.connected) {
              clearInterval(state.checkInterval);
              state.checkInterval = null;
              // console.log('重连成功')
              state.stompClient.send('/app/heartbeat', {
                atytopic: 'greetings'
              }, JSON.stringify({
                date: localStorage.getItem('initSocketDate')
              }));
            } else if (state.stompClient != null && state.websocket.readyState !== _sockjsClient.default.CONNECTING) {
              // 经常会遇到websocket的状态为open 但是stompClient的状态却是未连接状态，故此处需要把连接断掉 然后重连
              state.stompClient.disconnect(function () {
                _this2.commit('WEBSOCKET_CONNECT');
              });
            }
          }, 5000);
          state.checkInterval = interval;
        }
      } else {
        console.log('连接已建立成功，不再执行');
      }
    },
    WEBSOCKET_CONNECT: function WEBSOCKET_CONNECT(state) {
      var _this = this;
      var websock = new _sockjsClient.default(state.url);
      state.websocket = websock;
      // 获取STOMP子协议的客户端对象
      var stompClient = _stompjs.default.over(websock);
      stompClient.debug = null; // 关闭控制台打印
      stompClient.heartbeat.outgoing = 20000;
      stompClient.heartbeat.incoming = 0; // 客户端不从服务端接收心跳包
      // 向服务器发起websocket连接
      stompClient.connect({
        username: process.env.VUE_APP_STOMP_CLIENT_NAME,
        password: process.env.VUE_APP_STOMP_CLIENT_PASS
      }, function (frame) {
        console.log('链接成功！', state.listenerList);
        state.listenerList.forEach(function (item) {
          state.stompClient.subscribe(item.topic, item.callback);
        });
        // unsubscribe()可以用来取消客户端对这个目的地destination的订阅
        // stompClient.subscribe("/user/queue/message", msg => {
        //   // this.getData(msg);
        //   console.log(msg)
        // });
      }, function (err) {
        // 第一次连接失败和连接后断开连接都会调用这个函数 此处调用重连
        console.log('长链接断开，正在尝试重新链接...', err);
        setTimeout(function () {
          _this.commit('WEBSOCKET_INIT');
        }, 1000);
      });
      state.stompClient = stompClient;
    },
    WEBSOCKET_SEND: function WEBSOCKET_SEND(state, p) {
      state.stompClient.send(p.topic, {}, p.data);
    },
    WEBSOCKET_UNSUBSRCIBE: function WEBSOCKET_UNSUBSRCIBE(state, p) {
      state.stompClient.unsubscribe(p);
      for (var i = 0; i < state.listenerList.length; i++) {
        if (state.listenerList[i].topic === p) {
          state.listenerList.splice(i, 1);
          console.log('解除订阅：' + p + ' size:' + state.listenerList.length);
          break;
        }
      }
    }
  },
  actions: {
    WEBSOCKET_INIT: function WEBSOCKET_INIT(_ref) {
      var commit = _ref.commit;
      commit('WEBSOCKET_INIT');
    },
    WEBSOCKET_SEND: function WEBSOCKET_SEND(_ref2, p) {
      var commit = _ref2.commit;
      commit('WEBSOCKET_SEND', p);
    },
    WEBSOCKET_UNSUBSRCIBE: function WEBSOCKET_UNSUBSRCIBE(_ref3, p) {
      var commit = _ref3.commit;
      commit('WEBSOCKET_UNSUBSRCIBE', p);
    }
  }
});