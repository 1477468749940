"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _operation = require("@/api/operation");
var _auth = require("@/utils/auth");
var state = {
  brandList: [],
  brandListAll: [],
  payChannelList: []
};
var mutations = {
  SET_BRAND_LIST: function SET_BRAND_LIST(state, brandList) {
    state.brandList = brandList;
  },
  SET_BRAND_LIST_ALL: function SET_BRAND_LIST_ALL(state, brandList) {
    state.brandListAll = brandList;
  },
  SET_PAY_CHANNEL_LIST: function SET_PAY_CHANNEL_LIST(state, payChannelList) {
    state.payChannelList = payChannelList;
  }
};
var actions = {
  // get brandList
  getBrandList: function getBrandList(_ref) {
    var commit = _ref.commit,
      _ref$otherFlag = _ref.otherFlag,
      otherFlag = _ref$otherFlag === void 0 ? 0 : _ref$otherFlag;
    return new Promise(function (resolve, reject) {
      (0, _operation.brandList)({
        token: (0, _auth.getToken)(),
        otherFlag: otherFlag
      }).then(function (response) {
        var code = response.code,
          data = response.data;
        if (code !== 0 || !data) {
          reject('Failed to mount application list');
        }
        commit('SET_BRAND_LIST', data.list);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getBrandListAll: function getBrandListAll(_ref2) {
    var commit = _ref2.commit,
      _ref2$otherFlag = _ref2.otherFlag,
      otherFlag = _ref2$otherFlag === void 0 ? 1 : _ref2$otherFlag;
    return new Promise(function (resolve, reject) {
      (0, _operation.brandList)({
        token: (0, _auth.getToken)(),
        otherFlag: otherFlag
      }).then(function (response) {
        var code = response.code,
          data = response.data;
        if (code !== 0 || !data) {
          reject('Failed to mount application list');
        }
        commit('SET_BRAND_LIST_ALL', data.list);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getPayChannelList: function getPayChannelList(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      (0, _operation.getDictList)({
        dict_type: 'channelQuotient'
      }).then(function (response) {
        var code = response.code,
          data = response.data;
        if (code !== 0 || !data) {
          reject('Failed to mount application list');
        }
        for (var i in data.list) {
          data.list[i].dict_label = parseInt(data.list[i].dict_label);
        }
        commit('SET_PAY_CHANNEL_LIST', data.list);
        resolve(data);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};