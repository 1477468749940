import { render, staticRenderFns } from "./emoji.vue?vue&type=template&id=0da1221b&scoped=true"
import script from "./emoji.vue?vue&type=script&lang=js"
export * from "./emoji.vue?vue&type=script&lang=js"
import style0 from "./emoji.vue?vue&type=style&index=0&id=0da1221b&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da1221b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0da1221b')) {
      api.createRecord('0da1221b', component.options)
    } else {
      api.reload('0da1221b', component.options)
    }
    module.hot.accept("./emoji.vue?vue&type=template&id=0da1221b&scoped=true", function () {
      api.rerender('0da1221b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CustomerNimService/components/emoji.vue"
export default component.exports