"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.channelReal = channelReal;
exports.distributorsDelayReportList = distributorsDelayReportList;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 渠道实时
 * @param {*} params
 */
function channelReal(params) {
  return (0, _request.default)({
    url: '/channel/channelRealList',
    method: 'get',
    params: params
  });
}
/**
 * 二级分销渠道日报查询
 * @param {*} params
 */
function distributorsDelayReportList(params) {
  return (0, _request.default)({
    url: '/channel/distributorsDelayReportList',
    method: 'get',
    params: params
  });
}