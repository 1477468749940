"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendNotification = void 0;
var sendNotification = exports.sendNotification = function sendNotification(title, options) {
  if (!('Notification' in window)) {
    console.warn('This browser does not support desktop notification');
    return;
  }
  Notification.requestPermission(function (permission) {
    var notification;
    if (permission === 'granted') {
      notification = new Notification(title, options);
    } else if (permission !== 'denied') {
      // 请求通知权限
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          notification = new Notification(title, options);
        }
      });
    }
    if (notification) {
      notification.onclick = function (event) {
        window.focus();
        notification.close();
      };
    }
  });
};