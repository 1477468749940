"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "dialogDrag",
      rawName: "v-dialogDrag"
    }]
  }, [_c("transition", {
    attrs: {
      name: "el-zoom-in-center"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.messageLoading,
      expression: "messageLoading"
    }],
    staticClass: "chat"
  }, [_c("h4", {
    staticClass: "title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("customer.titleNim")) + "\n        "), _c("el-switch", {
    attrs: {
      disabled: _vm.customerOnlineDisabled,
      "active-value": "1",
      "inactive-value": "0",
      "active-color": "#18dd0e",
      "inactive-color": "#dadde5"
    },
    on: {
      change: _vm.customerOnlineChange
    },
    model: {
      value: _vm.customerOnline,
      callback: function callback($$v) {
        _vm.customerOnline = $$v;
      },
      expression: "customerOnline"
    }
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: _vm.hide
    }
  }), _vm._v(" "), _c("el-button", {
    staticClass: "centerBtn"
  }, [_vm._v("居中")])], 1), _vm._v(" "), _c("section", {
    staticClass: "chat-container"
  }, [_c("div", {
    staticClass: "chat-left"
  }, _vm._l(_vm.actionTypeList, function (item) {
    return _c("div", {
      key: item.value,
      class: "nav " + (_vm.messageNimActionType === item.value ? "active-nav" : ""),
      on: {
        click: function click($event) {
          return _vm.onChangeActionType(item.value);
        }
      }
    }, [_c("el-badge", {
      staticClass: "item",
      attrs: {
        "is-dot": "",
        hidden: _vm.getListUnread(item.value)
      }
    }, [_vm._v("\n              " + _vm._s(item.label) + "\n            ")])], 1);
  }), 0), _vm._v(" "), _c("ul", {
    staticClass: "chat-center"
  }, [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: _vm.$t("customer.pleaseInputUserId")
    },
    model: {
      value: _vm.userId,
      callback: function callback($$v) {
        _vm.userId = $$v;
      },
      expression: "userId"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-circle-plus-outline"
    },
    on: {
      click: _vm.launch
    },
    slot: "append"
  })], 1), _vm._v(" "), _vm.messageNimList.searchMessageList.length > 0 ? _c("div", {
    staticClass: "search-box"
  }, [_vm._l(_vm.messageNimList.searchMessageList, function (item) {
    return _c("li", {
      key: item._id,
      class: {
        active: _vm.remoteNimAccount && item.targetUserInfo._id == _vm.remoteNimAccount._id
      },
      on: {
        click: function click($event) {
          return _vm.openUser(item);
        }
      }
    }, [_c("el-badge", {
      staticClass: "item",
      attrs: {
        "is-dot": "",
        hidden: item.unread <= 0
      }
    }, [_c("img", {
      staticClass: "user-avatar",
      attrs: {
        src: item.targetUserInfo.avatarUrl
      }
    })]), _vm._v(" "), _c("p", {
      class: {
        active: item.targetUserInfo.isVip === 1,
        anchorNick: item.targetUserInfo.account_type == 2
      }
    }, [_vm._v(_vm._s(item.targetUserInfo.nickName))]), _vm._v(" "), item.targetUserInfo.payCount > 0 ? _c("div", {
      staticClass: "payCount"
    }, [_vm._v(_vm._s(item.targetUserInfo.payCount))]) : _vm._e()], 1);
  }), _vm._v(" "), _c("div", {
    staticClass: "search-line"
  }, [_vm._v("-- 搜索 "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClearSearch
    }
  }, [_vm._v("清除搜索")]), _vm._v(" --")], 1)], 2) : _vm._e(), _vm._v(" "), _c("recycle-scroller", {
    staticStyle: {
      height: "calc(100% - 28px)"
    },
    attrs: {
      items: _vm.list,
      "item-size": 44.39,
      "key-field": "id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c("li", {
          class: {
            active: _vm.remoteNimAccount && item.targetUserInfo._id == _vm.remoteNimAccount._id,
            itemtopCustom: item.isTop
          },
          on: {
            click: function click($event) {
              return _vm.openUser(item);
            }
          }
        }, [_c("el-badge", {
          staticClass: "item",
          attrs: {
            "is-dot": "",
            hidden: item.unread <= 0
          }
        }, [_c("img", {
          staticClass: "user-avatar",
          attrs: {
            src: item.targetUserInfo.avatarUrl
          }
        })]), _vm._v(" "), _c("p", {
          class: {
            active: item.targetUserInfo.isVip === 1,
            anchorNick: item.targetUserInfo.account_type == 2
          }
        }, [_vm._v(_vm._s(item.targetUserInfo.nickName))]), _vm._v(" "), item.targetUserInfo.payCount > 0 ? _c("div", {
          staticClass: "payCount"
        }, [_vm._v(_vm._s(item.targetUserInfo.payCount))]) : _vm._e()], 1)];
      }
    }])
  })], 1), _vm._v(" "), _vm.remoteNimAccount ? _c("div", {
    staticClass: "chat-right"
  }, [_c("div", {
    staticClass: "chat-record"
  }, [_c("div", {
    staticClass: "chat-record-header"
  }, [_c("img", {
    staticClass: "chat-record-header-avatar",
    attrs: {
      src: _vm.remoteNimAccount.avatarUrl
    }
  }), _vm._v(" "), _c("p", [_c("span", {
    class: {
      active: _vm.remoteNimAccount.isVip === 1
    }
  }, [_vm._v(_vm._s(_vm.remoteNimAccount.nickName))]), _vm._v("(" + _vm._s(_vm.remoteNimAccount._id) + ") "), _c("br"), _vm._v("\n                " + _vm._s(_vm.$t("table.belongs")) + ":" + _vm._s(_vm.remoteNimAccount.appName) + " " + _vm._s(_vm.$t("table.country")) + ":" + _vm._s(_vm.remoteNimAccount.countryCode) + " "), _c("br"), _vm._v("\n                " + _vm._s(_vm.$t("table.channel")) + "：" + _vm._s(_vm.remoteNimAccount.channelNo) + " " + _vm._s(_vm.$t("table.edition")) + "：" + _vm._s(_vm.remoteNimAccount.versions) + " "), _c("br"), _vm._v(" "), _vm.remoteNimAccount.time ? _c("span", [_vm._v(_vm._s(_vm.$t("table.expiredAt")) + "：" + _vm._s(_vm.remoteNimAccount.time))]) : _vm._e()]), _vm._v(" "), _c("p", {
    staticClass: "topCustom",
    on: {
      click: function click($event) {
        return _vm.topOperation(_vm.messageInfo);
      }
    }
  }, [_vm._v(_vm._s(_vm.messageInfo.isTop ? "取消置顶" : "置顶"))])]), _vm._v(" "), _c("ul", {
    ref: "chatRecordContainer",
    staticClass: "chat-record-container"
  }, _vm._l(_vm.historyMessagesNim, function (item) {
    return _c("li", {
      key: item.id,
      class: {
        right: item.from && item.from == "666666"
      }
    }, [item.text != "UnknownMessage" ? [_c("div", [_c("p", {
      staticClass: "chat-record-list-user"
    }, [item.from == "666666" ? _c("span", [_vm._v(_vm._s(_vm._f("dateTimeFilter")(item.time, "MM-dd hh:mm")))]) : _vm._e(), _vm._v("\n                      " + _vm._s(item.fromNick) + "\n                      "), item.from != "666666" ? _c("span", [_vm._v(_vm._s(_vm._f("dateTimeFilter")(item.time, "MM-dd hh:mm")))]) : _vm._e()]), _vm._v(" "), item.type == "text" ? _c("div", {
      staticClass: "chat-record-list-context",
      domProps: {
        innerHTML: _vm._s(item.text)
      }
    }) : _vm._e(), _vm._v(" "), item.type == "image" ? _c("el-image", {
      staticStyle: {
        width: "100px",
        height: "100px"
      },
      attrs: {
        src: item.file.url,
        fit: "cover",
        "preview-src-list": [item.file.url]
      }
    }) : _vm._e(), _vm._v(" "), item.type == "video" ? _c("img", {
      directives: [{
        name: "video-viewer",
        rawName: "v-video-viewer",
        value: item.file.url,
        expression: "item.file.url"
      }],
      attrs: {
        height: "100px",
        src: item.file.url + "?vframe"
      }
    }) : _vm._e()], 1)] : _vm._e()], 2);
  }), 0)]), _vm._v(" "), _c("div", {
    staticClass: "chat-input"
  }, [_c("p", {
    staticClass: "chat-input-icon"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "emoji"
    },
    on: {
      click: function click($event) {
        _vm.isShowEmoji = !_vm.isShowEmoji;
      }
    }
  }), _vm._v(" "), _c("el-upload", {
    staticStyle: {
      display: "inline"
    },
    attrs: {
      action: "/api/file/upload",
      data: _vm.uploadData,
      "show-file-list": false,
      "on-success": _vm.handleAvatarSuccess
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "image"
    }
  })], 1), _vm._v(" "), _c("svg-icon", {
    attrs: {
      "icon-class": "recharge-url"
    },
    on: {
      click: _vm.payurl
    }
  }), _vm._v(" "), _c("svg-icon", {
    attrs: {
      "icon-class": "vip-url"
    },
    on: {
      click: _vm.vipurl
    }
  }), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      trigger: "click",
      "hide-on-click": false
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("customer.quickReply")) + "\n                ")]), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "project-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    staticStyle: {
      height: "50px",
      display: "flex",
      "align-items": "center",
      position: "relative"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "选择分类"
    },
    on: {
      change: _vm.getList
    },
    model: {
      value: _vm.listQuery.classify_id,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "classify_id", $$v);
      },
      expression: "listQuery.classify_id"
    }
  }, _vm._l(_vm.classifyList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _vm._v(" "), _vm._l(_vm.quickMessageList, function (item) {
    return _c("el-dropdown-item", {
      key: item.id,
      attrs: {
        command: item.content
      }
    }, [_vm._v(_vm._s(item.content))]);
  })], 2)], 1)], 1), _vm._v(" "), _c("el-input", {
    staticClass: "textarea",
    attrs: {
      type: "textarea",
      placeholder: _vm.$t("customer.pleaseInputMassage")
    },
    nativeOn: {
      keydown: function keydown($event) {
        return _vm.handleKeyCode($event);
      },
      "!paste": function paste($event) {
        $event.preventDefault();
        return _vm.pasting.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "chat-input-submit"
  }, [_c("button", {
    class: {
      active: _vm.inputValue.trim() !== ""
    },
    on: {
      click: _vm.send
    }
  }, [_vm._v(_vm._s(_vm.$t("customer.send")))])]), _vm._v(" "), _vm.isShowEmoji ? _c("emoji", {
    staticClass: "emoji",
    on: {
      checked: _vm.checkEmoji
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    staticClass: "dialogChat",
    attrs: {
      "modal-append-to-body": false,
      title: "发送图片",
      visible: _vm.dialogPicVisible,
      width: "30%",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogPicVisible = $event;
      }
    }
  }, [_c("el-image", {
    staticStyle: {
      width: "100%",
      "max-height": "500px"
    },
    attrs: {
      fit: "contain",
      src: _vm.dialogImgSrc
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSendImg
    }
  }, [_vm._v("发送")])], 1)], 1)], 1)])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;