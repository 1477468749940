"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    style: {
      width: _vm.videoWidth,
      height: _vm.videoHeight,
      margin: "0 auto"
    }
  }, [!_vm.isPlaying ? _c("img", {
    attrs: {
      width: "100%",
      height: "100%",
      src: _vm.picDefUrl
    },
    on: {
      click: function click($event) {
        return _vm.playVideo();
      },
      error: function error($event) {
        _vm.picDefUrl = "http://cdn-media-de.hala.icu/goods/video.png";
      }
    }
  }) : _c("video", {
    ref: "myVideo",
    staticClass: "myVideo",
    attrs: {
      src: _vm.videoUrl,
      width: "100%",
      height: "100%",
      controls: "",
      autoplay: "",
      muted: ""
    },
    domProps: {
      muted: true
    },
    on: {
      ended: function ended($event) {
        return _vm.stopVideo();
      }
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;