"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.device === "mobile" && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _vm._v(" "), _c("sidebar", {
    staticClass: "sidebar-container"
  }), _vm._v(" "), _c("div", {
    staticClass: "main-container",
    class: {
      hasTagsView: _vm.needTagsView
    }
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixedHeader
    }
  }, [_c("navbar"), _vm._v(" "), _vm.needTagsView ? _c("tags-view") : _vm._e()], 1), _vm._v(" "), _c("app-main")], 1), _vm._v(" "), _c("customer-nim-service", {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: ["superAdmin", "operation", "customer", "PartimeOperator"],
      expression: "['superAdmin', 'operation', 'customer', 'PartimeOperator']"
    }]
  }), _vm._v(" "), _c("el-drawer", {
    attrs: {
      title: "实时推送列表",
      visible: _vm.drawer
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.drawer = $event;
      }
    }
  }, [_vm._l(_vm.taskList, function (item) {
    return _c("div", {
      key: item.timestamp,
      staticClass: "messageItem"
    }, [_vm._v("\n      " + _vm._s("\u3010".concat(item.username, "\u3011\u6267\u884C\u4E86\u5B9E\u65F6\u63A8\u9001\u53D1\u9001\u4EFB\u52A1 \u6807\u9898: \u3010").concat(item.title, "\u3011\u8FDB\u5EA6: ").concat(item.userNum, " / ").concat(item.loadNum)) + "\n    ")]);
  }), _vm._v(" "), _vm.taskList.length === 0 ? _c("div", {
    staticClass: "empty"
  }, [_vm._v("-- 暂无数据 --")]) : _vm._e()], 2), _vm._v(" "), !_vm.drawer ? _c("el-tooltip", {
    staticClass: "q-notification right-menu-item item",
    attrs: {
      effect: "dark",
      content: "实时推送列表",
      placement: "bottom"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "list"
    },
    on: {
      click: function click($event) {
        _vm.drawer = true;
      }
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;