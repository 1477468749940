"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      passwd: '',
      passwdError: false,
      pass: false,
      redirect: undefined
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['lockPasswd'])),
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
        }
      },
      immediate: true
    }
  },
  methods: {
    handleLogout: function handleLogout() {
      var _this = this;
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.$store.dispatch('user/logout').then(function () {
          _this.$router.push({
            path: '/login'
          });
        });
      }).catch(function () {
        return false;
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      if (this.passwd !== this.lockPasswd) {
        this.passwd = '';
        this.$message({
          message: '解锁密码错误,请重新输入',
          type: 'error'
        });
        this.passwdError = true;
        setTimeout(function () {
          _this2.passwdError = false;
        }, 1000);
        return;
      }
      this.pass = true;
      this.$store.commit('user/CLEAR_LOCK');
      setTimeout(function () {
        _this2.$router.push({
          path: _this2.redirect
        });
      }, 100);
    }
  }
};