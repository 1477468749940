"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anchorBrokerIncome = anchorBrokerIncome;
exports.anchorIncomeList = anchorIncomeList;
exports.anchorSettlementList = anchorSettlementList;
exports.borkerList = borkerList;
exports.brokerIncomeInfo = brokerIncomeInfo;
exports.deleteBroker = deleteBroker;
exports.editBroker = editBroker;
exports.settlementList = settlementList;
exports.updateSettleStatus = updateSettleStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 获取经纪人列表
 * @param {*} params
 */
function borkerList(params) {
  return (0, _request.default)({
    url: '/broker/list',
    method: 'get',
    params: params
  });
}

/**
 * 删除经纪人
 * @param {*} data
 */
function deleteBroker(data) {
  return (0, _request.default)({
    url: '/broker/delete',
    method: 'post',
    data: data
  });
}

/**
 * 编辑经纪人信息
 * @param {*} data
 */
function editBroker(data) {
  return (0, _request.default)({
    url: '/broker/edit',
    method: 'post',
    data: data
  });
}

/**
 * 获取主播收入明细
 * @param {*} params
 */
function anchorIncomeList(params) {
  return (0, _request.default)({
    url: '/broker/income',
    method: 'GET',
    params: params
  });
}

/**
 * 获取主播收入明细-公会后台
 * @param {*} params
 */
function anchorBrokerIncome(params) {
  return (0, _request.default)({
    url: '/anchorBroker/income',
    method: 'GET',
    params: params
  });
}

// 公会结算
function settlementList(params) {
  return (0, _request.default)({
    url: '/broker/settlement/list',
    method: 'GET',
    params: params
  });
}

// 公会结算状态修改
function updateSettleStatus(data) {
  return (0, _request.default)({
    url: '/broker/settlement/edit',
    method: 'post',
    data: data
  });
}

// 主播结算列表
function anchorSettlementList(params) {
  return (0, _request.default)({
    url: '/broker/settlement/anchorList',
    method: 'GET',
    params: params
  });
}

// 获取工会实时数据
function brokerIncomeInfo(params) {
  return (0, _request.default)({
    url: '/broker/brokerIncomeInfo',
    method: 'GET',
    params: params
  });
}