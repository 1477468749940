"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "emoji"
  }, _vm._l(_vm.EmojiList, function (emoji) {
    return _c("li", {
      key: emoji.unicode,
      attrs: {
        title: emoji.symbol
      },
      on: {
        click: function click($event) {
          return _vm.checkEmoji(emoji);
        }
      }
    }, [_vm._v(_vm._s(emoji.emoji))]);
  }), 0);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;