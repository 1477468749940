"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _components = _interopRequireDefault(require("./components"));
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Chat: _components.default
  },
  data: function data() {
    return {
      visible: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['roles', 'totalUnreadCount'])),
  methods: {
    switchBtn: function switchBtn() {
      if (this.roles[0] === 'admin' || this.roles[0] === 'customer' || this.roles[0] === 'operation' || this.roles[0] === 'superAdmin') {
        this.$nim.init();
      }
    }
  }
};