"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anchorAnalysisList = anchorAnalysisList;
exports.anchorCoreReportList = anchorCoreReportList;
exports.anchorDateRangeTimer = anchorDateRangeTimer;
exports.anchorDetailedReportList = anchorDetailedReportList;
exports.anchorInviteIncomeRelation = anchorInviteIncomeRelation;
exports.anchorInviteRelation = anchorInviteRelation;
exports.anchorRightList = anchorRightList;
exports.baseReportList = baseReportList;
exports.brokerAnchorReportList = brokerAnchorReportList;
exports.countryAnchorList = countryAnchorList;
exports.informationFeedback = informationFeedback;
exports.rankList = rankList;
exports.retainedUserReportList = retainedUserReportList;
exports.userRefoundAnchorDecrRecord = userRefoundAnchorDecrRecord;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 艺人明细
 * @param {*} params
 */
function anchorDetailedReportList(params) {
  return (0, _request.default)({
    url: '/anchorReport/detailedReportList',
    method: 'get',
    params: params
  });
}

/**
 * 主播核心报表
 * @param {*} params
 */
function anchorCoreReportList(params) {
  return (0, _request.default)({
    url: '/anchorReport/coreReportList',
    method: 'get',
    params: params
  });
}

/**
 * 根据时间段执行核心报表任务
 * @param {*} params
 */
function anchorDateRangeTimer(params) {
  return (0, _request.default)({
    url: '/anchorReport/anchorDateRangeTimer',
    method: 'get',
    params: params
  });
}

/**
 * 工会主播艺人明细
 * @param {*} params
 */
function brokerAnchorReportList(params) {
  return (0, _request.default)({
    url: '/anchorReport/brokerAnchorReportList',
    method: 'get',
    params: params
  });
}

/**
 * 获取主播日榜周榜
 * @param {*} params
 */
function rankList(params) {
  return (0, _request.default)({
    url: '/anchorReport/rankList',
    method: 'get',
    params: params
  });
}

/**
 * 国家主播日报
 * @param {*} params
 */
function countryAnchorList(params) {
  return (0, _request.default)({
    url: '/anchorReport/countryAnchorList',
    method: 'get',
    params: params
  });
}

/**
 * 主播数据分析
 * @param {*} params
 */
function anchorAnalysisList(params) {
  return (0, _request.default)({
    url: '/anchorReport/anchorAnalysisList',
    method: 'get',
    params: params
  });
}

/**
 * 主播收入分布表
 * @param {*} params
 */
function baseReportList(params) {
  return (0, _request.default)({
    url: '/anchorReport/baseReportList',
    method: 'get',
    params: params
  });
}

/**
 * 主播特权报表
 */
function anchorRightList(params) {
  return (0, _request.default)({
    url: '/anchorReport/anchorRightList',
    method: 'get',
    params: params
  });
}

/**
 * 用户次留日报
 * @param {*} params
 */
function retainedUserReportList(params) {
  return (0, _request.default)({
    url: '/anchorReport/retainedUserReportList',
    method: 'get',
    params: params
  });
}

/**
 * 主播邀请用户主播记录
 * @param {*} params
 */
function anchorInviteRelation(params) {
  return (0, _request.default)({
    url: '/anchorReport/anchorInviteRelation',
    method: 'get',
    params: params
  });
}

/**
 * 主播邀请用户主播收益列表
 * @param {*} params
 */
function anchorInviteIncomeRelation(params) {
  return (0, _request.default)({
    url: '/anchorReport/anchorInviteIncomeRelation',
    method: 'get',
    params: params
  });
}

/**
 * 用户退款扣除主播金币记录
 * @param {*} params
 */
function userRefoundAnchorDecrRecord(params) {
  return (0, _request.default)({
    url: '/anchorReport/userRefoundAnchorDecrRecord',
    method: 'get',
    params: params
  });
}

/**
 * 用户反馈记录
 * @param {*} params
 */
function informationFeedback(params) {
  return (0, _request.default)({
    url: '/anchorReport/informationFeedback',
    method: 'get',
    params: params
  });
}