"use strict";

var _interopRequireWildcard = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/core-js/modules/es6.array.iterator.js");
require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/core-js/modules/es6.promise.js");
require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/core-js/modules/es6.object.assign.js");
require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _nim = _interopRequireDefault(require("@/utils/nim"));
require("@/utils/dialogDrag");
var _vueSocket = _interopRequireDefault(require("vue-socket.io"));
// a modern alternative to CSS resets

// global css

// internationalization
// icon
// permission control
// error log

// global filters

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'mini',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.use(new _vueSocket.default({
  debug: true,
  connection: process.env.VUE_APP_SOCKET_PATH,
  // 此处可以使用代理地址,/socket为 http:localhost:8000, // 'http://127.0.0.1:7003/' // 此处可以使用代理地址,/socket为 http:localhost:8000
  options: {
    path: '',
    reconnection: true,
    reconnectionDelay: 5000
  }
}));

// socket
// import websocket from './store/modules/webSockets'
// Vue.prototype.$websocket = websocket

_vue.default.prototype.$nim = _nim.default;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  VueClipboard: _vueClipboard.default,
  render: function render(h) {
    return h(_App.default);
  }
});