"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DashboardAdmin',
  components: {
    PanelGroup: _PanelGroup.default
  },
  data: function data() {
    return {};
  },
  methods: {}
};