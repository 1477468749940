"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "google-auth"
  }, [_c("p", {
    on: {
      click: function click($event) {
        _vm.googleAuth = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.bindGoogleAuth")))]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.$t("navbar.bindGoogleAuth"),
      visible: _vm.googleAuth,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false,
      width: "700px",
      top: "10vh",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.googleAuth = $event;
      }
    }
  }, [_c("ul", [_c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("1、" + _vm._s(_vm.$t("googleAuth.step1")))]), _vm._v(" "), _c("div", {
    staticClass: "download-con"
  }, [_c("div", {
    staticClass: "app-link"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/google_auth_ios.png")
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_c("a", {
    attrs: {
      href: "https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8",
      target: "_blank",
      title: ""
    }
  }, [_vm._v("\n                Download for IOS\n              ")])])], 1), _vm._v(" "), _c("div", {
    staticClass: "app-link"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/google_auth_android.png")
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_c("a", {
    attrs: {
      href: "https://jms-pkg.oss-cn-beijing.aliyuncs.com/Google%20Authenticator_v5.10_apkpure.com.apk",
      target: "_blank",
      title: ""
    }
  }, [_vm._v("\n                Download for Android\n              ")])])], 1)])]), _vm._v(" "), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("2、" + _vm._s(_vm.$t("googleAuth.step2")))]), _vm._v(" "), _c("div", {
    staticClass: "miyao"
  }, [_c("img", {
    attrs: {
      src: _vm.googleImg
    }
  }), _vm._v(" "), _c("div", [_c("p", [_vm._v(_vm._s(_vm.form.googleKey))]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.form.googleKey,
      expression: "form.googleKey",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.clipboardSuccess,
      expression: "clipboardSuccess",
      arg: "success"
    }],
    attrs: {
      size: "medium",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("googleAuth.copyCode")))])], 1)])]), _vm._v(" "), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("3、" + _vm._s(_vm.$t("googleAuth.step3")))]), _vm._v(" "), _c("div", {
    staticClass: "remarks"
  }, [_c("i", {
    staticClass: "el-icon-warning"
  }), _vm._v(_vm._s(_vm.$t("googleAuth.tip")))])])]), _vm._v(" "), _c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.formRules,
      model: _vm.form,
      size: "medium"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("form.loginPwd"),
      prop: "loginPwd"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password"
    },
    model: {
      value: _vm.form.loginPwd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "loginPwd", $$v);
      },
      expression: "form.loginPwd"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("form.googleCode"),
      prop: "googleCode"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.googleCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "googleCode", $$v);
      },
      expression: "form.googleCode"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "medium",
      type: "primary"
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.$t("form.confirmSubmit")))])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;