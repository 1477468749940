"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.filterAsyncRoutesOld = filterAsyncRoutesOld;
var _objectSpread2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router = require("@/router");
var _layout = _interopRequireDefault(require("@/layout"));
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 */
function filterAsyncRoutes(routers) {
  var res = [];
  routers.forEach(function (route) {
    var temp = (0, _objectSpread2.default)({}, route);
    if (temp.component) {
      if (temp.component === 'layout') {
        temp.component = _layout.default;
        temp.path = "/".concat(temp.path);
      } else {
        temp.component = loadView(temp.component);
      }
    }
    if (temp.children) {
      temp.children = filterAsyncRoutes(temp.children);
    }
    res.push(temp);
  });
  res.push({
    path: '*',
    redirect: '/404',
    hidden: true
  });
  return res;
}
var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutesOld(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutesOld(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, _ref) {
    var routes = _ref.accessedRoutes,
      roles = _ref.roles;
    state.addRoutes = routes;
    var newRouter = _router.constantRoutes;
    if (roles.includes('channel')) {
      newRouter.shift();
    }
    state.routes = newRouter.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref2, _ref3) {
    var commit = _ref2.commit;
    var routerList = _ref3.routerList,
      roles = _ref3.roles;
    return new Promise(function (resolve) {
      var accessedRoutes = [];
      var rolelist = ['brokerCn', 'channelCn', 'channel', 'broker'];
      if (roles.some(function (role) {
        return rolelist.includes(role);
      }) || !routerList || routerList.length === 0) {
        accessedRoutes = filterAsyncRoutesOld(_router.asyncRoutes, roles);
      } else {
        accessedRoutes = filterAsyncRoutes(routerList);
      }
      commit('SET_ROUTES', {
        accessedRoutes: accessedRoutes,
        roles: roles
      });
      resolve(accessedRoutes);
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};