"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _CustomerNimService = _interopRequireDefault(require("@/components/CustomerNimService"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
var _permission = _interopRequireDefault(require("@/directive/permission"));
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    CustomerNimService: _CustomerNimService.default
  },
  directives: {
    permission: _permission.default
  },
  mixins: [_ResizeHandler.default],
  data: function data() {
    return {
      taskList: [],
      drawer: false
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), (0, _vuex.mapGetters)(['name'])), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  mounted: function mounted() {
    this.$socket.emit('connect', 1);
    // this.sockets.connect()
  },
  sockets: {
    connect: function connect() {
      var _this = this;
      var id = localStorage.getItem('socketId');
      if (!id) {
        id = this.$socket.id;
        localStorage.setItem('socketId', id);
      }
      console.log('socket链接成功', id);
      this.$socket.emit('join', {
        room: 'adminUser',
        userId: this.name
      });
      this.sockets.subscribe(id, function (data) {
        console.log('socket.io', data);
      });
      this.sockets.subscribe('online', function (result) {
        console.log('其他管理员登录', result);
      });
      this.sockets.subscribe('initTask', function (result) {
        console.log('initTask', result);
        if (result) {
          var taskList = [];
          for (var i = 0; i < result.length; i++) {
            var taskInfo = JSON.parse(result[i]);
            console.log('taskList', taskInfo);
            taskList.push(taskInfo);
          }
          console.log('taskList', taskList);
          _this.taskList = taskList;
        }
      });
      this.sockets.subscribe('addTask', function (result) {
        console.log('addTask', result);
        if (result) {
          _this.taskList.push(result);
        }
      });
      this.sockets.subscribe('loadTask', function (result) {
        console.log('loadTask', result);
        var taskInfoIndex = _this.taskList.findIndex(function (item) {
          return item.username === result.username && item.timestamp === result.timestamp && item.title === result.title;
        });
        if (taskInfoIndex !== -1) {
          _this.$set(_this.taskList, taskInfoIndex, result);
        }
      });
      this.sockets.subscribe('succTask', function (result) {
        console.log('succTask', result);
        var taskInfoIndex = _this.taskList.findIndex(function (item) {
          return item.username === result.username && item.timestamp === result.timestamp && item.title === result.title;
        });
        if (taskInfoIndex !== -1) {
          setTimeout(function () {
            // 移除
            _this.taskList.splice(taskInfoIndex, 1);
          }, 3000);
        }
      });
      this.sockets.subscribe('errorTask', function (result) {
        console.log('errorTask', result);
      });
    },
    // reconnect重连
    reconnect: function reconnect() {
      console.log('socket重新链接');
    },
    disconnect: function disconnect() {
      var _this2 = this;
      // this.$socket.disconnect()
      console.log('socket断开链接');
      var taskNames = ['online', 'addTask', 'loadTask', 'succTask', 'errorTask'];
      var _loop = function _loop(i) {
        _this2.sockets.unsubscribe(taskNames[i], function (result) {
          console.log(taskNames[i], result);
        });
      };
      for (var i = 0; i < taskNames.length; i++) {
        _loop(i);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$socket.disconnect();
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    format: function format(percentage) {
      console.log(percentage);
      return percentage === 100 ? '满' : "".concat(percentage, "%");
    }
  }
};