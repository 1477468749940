"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.systemType ? _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "item real"
  }, [_c("div", {
    staticClass: "itemRow fontW"
  }, [_c("span", [_vm._v("Date: " + _vm._s(_vm._f("dateTimeFilter")(_vm.brokerIncomeInfo.beginDate, "yyyy-MM-dd")) + " ~ " + _vm._s(_vm._f("dateTimeFilter")(_vm.brokerIncomeInfo.endDate, "yyyy-MM-dd")))])]), _vm._v(" "), _c("div", {
    staticClass: "itemRow"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("table.generalDiamonds")) + ": ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(parseInt(_vm.brokerIncomeInfo.total)))])]), _vm._v(" "), _c("div", {
    staticClass: "itemRow"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("table.estimateEarning")) + ": ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.brokerIncomeInfo.allAmount))])]), _vm._v(" "), _c("div", {
    staticClass: "itemRow"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("table.agentBenefit")) + ": ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.brokerIncomeInfo.agentAmount))])]), _vm._v(" "), _c("div", {
    staticClass: "itemRow"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("table.salariedGirls")) + ": ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.brokerIncomeInfo.salariedNum))])]), _vm._v(" "), _c("div", {
    staticClass: "itemRow"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("table.totalAnchors")) + ": ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.brokerIncomeInfo.totalAnchors))])]), _vm._v(" "), _c("div", {
    staticClass: "itemRow"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("table.activeAnchors")) + ": ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.brokerIncomeInfo.activeAnchors))])])]), _vm._v(" "), _c("div", {
    staticClass: "forceRefresh",
    on: {
      click: _vm.forceRefresh
    }
  }, [_vm._v("Force Refresh")])]) : _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "forceRefresh",
    on: {
      click: _vm.forceRefresh
    }
  }, [_vm._v("Force Refresh")]), _vm._v(" "), _c("h4", {
    staticClass: "page-class-title"
  }, [_vm._v("Date: " + _vm._s(_vm._f("dateTimeFilter")(_vm.brokerIncomeInfo.beginDate, "yyyy-MM-dd")) + " ~ " + _vm._s(_vm._f("dateTimeFilter")(_vm.brokerIncomeInfo.endDate, "yyyy-MM-dd")))]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-width": "200px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("table.generalDiamonds")
    }
  }, [_vm._v(_vm._s(parseInt(_vm.brokerIncomeInfo.total)))])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("table.estimateEarning")
    }
  }, [_vm._v(_vm._s(_vm.brokerIncomeInfo.allAmount))])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("table.agentBenefit")
    }
  }, [_vm._v(_vm._s(_vm.brokerIncomeInfo.agentAmount))])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("table.salariedGirls")
    }
  }, [_vm._v(_vm._s(_vm.brokerIncomeInfo.salariedNum))])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("table.totalAnchors")
    }
  }, [_vm._v(_vm._s(_vm.brokerIncomeInfo.totalAnchors))])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("table.activeAnchors")
    }
  }, [_vm._v(_vm._s(_vm.brokerIncomeInfo.activeAnchors))])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;