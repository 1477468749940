"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countWorks = countWorks;
exports.createChunks = createChunks;
/**
 * 分片
 * @param {*} data - 数据
 * @param {*} chunkSize - 分片数量
 */
function createChunks(data, chunkSize) {
  var result = [];
  for (var i = 0; i < data.length; i += chunkSize) {
    result.push(data.slice(i, i + chunkSize));
  }
  return result;
}

/**
 * @param {*} workNum 线程数
 */
function countWorks(workNum) {
  var maxThreads = navigator.hardwareConcurrency || 1;
  for (var i = 0; i < maxThreads.length; i++) {
    var worker = new Worker();
    worker.postMessage();
  }
  console.log(maxThreads);
}