"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find-index");
var state = {
  messageActionType: 0,
  messageList: {
    // 用户会话列表
    userMessageList: [],
    // 主播会话列表
    anchorMessageList: [],
    // 充值用户会话列表
    rechargeMessageList: [],
    // vip用户会话列表
    vipMessageList: [],
    // 置顶会话列表
    topMessageList: [],
    // 搜索会话列表
    searchMessageList: []
  },
  // 历史消息
  historyMessages: [],
  // 对方用户
  remoteAccount: null,
  // 所有会话未读数
  totalUnreadCount: 0,
  // 会话处理loading
  messageLoading: true
};
var isUnread = function isUnread(list, item) {
  var findIndex = list.findIndex(function (i) {
    return i.id === item.id;
  });
  if (findIndex === -1) {
    item.unread ? list.unshift(item) : list.push(item);
  } else {
    if (state.remoteAccount && Number(list[findIndex].to) === state.remoteAccount._id) {
      list[findIndex].unread = 0;
    } else {
      // 移动会话到最前面
      list.splice(findIndex, 1);
      list.unshift(item);
    }
  }
};
var mutations = {
  // 设置会话列表
  SET_MESSAGE_LIST: function SET_MESSAGE_LIST(state, list) {
    state.totalUnreadCount = 0;
    if (!list || list.length === 0) return [];
    list.forEach(function (item) {
      if (!item.isTop) {
        var topIndex = state.messageList.topMessageList.findIndex(function (i) {
          return i.id === item.id;
        });
        state.messageList.topMessageList.splice(topIndex, 1);
      }
      switch (true) {
        case state.messageList.searchMessageList.find(function (sItem) {
          return item.to === sItem.to;
        }):
          isUnread(state.messageList.searchMessageList, item);
          break;
        case item.isTop:
          isUnread(state.messageList.topMessageList, item);
          break;
        case item.targetUserInfo && item.targetUserInfo.account_type === 2:
          isUnread(state.messageList.anchorMessageList, item);
          break;
        case item.targetUserInfo && item.targetUserInfo.payCount > 0:
          isUnread(state.messageList.rechargeMessageList, item);
          break;
        case item.targetUserInfo && item.targetUserInfo.isVip === 1:
          isUnread(state.messageList.vipMessageList, item);
          break;
        default:
          isUnread(state.messageList.userMessageList, item);
      }
    });
    state.messageLoading = false;
  },
  // 搜索添加会话
  ADD_MESSAGE_LIST: function ADD_MESSAGE_LIST(state, row) {
    state.messageList.searchMessageList.unshift(row);
  },
  // 设置历史消息
  SET_HISTORY_MESSAGES: function SET_HISTORY_MESSAGES(state, list) {
    state.historyMessages = list;
  },
  // 添加消息
  ADD_HISTORY_MESSAGES: function ADD_HISTORY_MESSAGES(state, message) {
    var obj = {
      cc: true,
      flow: 'in',
      from: '666666',
      fromClientType: 'Web',
      fromDeviceId: '07757132-7BEE-47F4-A69E-43A1A26E1C75',
      fromNick: 'Customer service',
      idClient: '31beff1e-9c55-449e-9a5a-597b197a77dc',
      idServer: '440601024177',
      isHistoryable: true,
      isLocal: false,
      isOfflinable: true,
      isPushable: true,
      isReplyMsg: true,
      isRoamingable: false,
      isSyncable: true,
      isUnreadable: true,
      needMsgReceipt: false,
      needPushNick: true,
      scene: 'p2p',
      sessionId: 'p2p-' + message.user._id,
      status: 'success',
      target: message.user._id,
      text: message.message,
      time: Date.parse(new Date()),
      to: message.user._id,
      type: 'text',
      userUpdateTime: Date.parse(new Date())
    };
    var messages = state.historyMessages;
    messages.push(obj);
    state.historyMessages = messages;
  },
  // 设置对方用户
  SET_CHATREMOTE_ACCOUNT: function SET_CHATREMOTE_ACCOUNT(state, info) {
    state.remoteAccount = info;
    if (info) {
      var messageList = state.messageList;
      // let tempIndex = -1
      // 计算未读数
      // messageList.forEach((item, index) => {
      //   if (item.to === info._id + '') {
      //     const count = state.totalUnreadCount
      //     state.totalUnreadCount = count - item.unread
      //     item.unread = 0
      //     // tempIndex = index
      //   }
      // })
      // 用户列表重排
      // 临时存储对话信息
      // const tempMessage = (messageList.splice(tempIndex, 1))[0]
      // 将当前对话置顶
      // messageList.unshift(tempMessage)
      state.messageList = messageList;
    }
  },
  SET_ACTION_TYPE: function SET_ACTION_TYPE(state, actionType) {
    state.messageActionType = actionType;
  },
  CLEAR_SEARCH_LIST: function CLEAR_SEARCH_LIST(state) {
    state.messageList.searchMessageList = [];
  }
};
var actions = {
  // 设置会话列表
  setMessageList: function setMessageList(_ref, data) {
    var commit = _ref.commit;
    commit('SET_MESSAGE_LIST', data);
  },
  // 设置历史消息
  setHistoryMessages: function setHistoryMessages(_ref2, data) {
    var commit = _ref2.commit;
    commit('SET_HISTORY_MESSAGES', data);
  },
  // 添加消息
  addHistoryMessages: function addHistoryMessages(_ref3, data) {
    var commit = _ref3.commit;
    commit('ADD_HISTORY_MESSAGES', data);
  },
  // 设置对方用户
  setRemoteAccount: function setRemoteAccount(_ref4, data) {
    var commit = _ref4.commit;
    commit('SET_CHATREMOTE_ACCOUNT', data);
  },
  addMessageList: function addMessageList(_ref5, data) {
    var commit = _ref5.commit;
    commit('ADD_MESSAGE_LIST', data);
  },
  setActionType: function setActionType(_ref6, data) {
    var commit = _ref6.commit;
    commit('SET_ACTION_TYPE', data);
  },
  clearSearchList: function clearSearchList(_ref7) {
    var commit = _ref7.commit;
    commit('CLEAR_SEARCH_LIST');
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};