"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/lock',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/common/lock'));
    });
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
// 公会管理
{
  path: '/brokerManage',
  component: _layout.default,
  redirect: '/brokerManage/index',
  name: 'brokerManage',
  meta: {
    title: 'brokerManage',
    icon: 'broker',
    roles: ['broker'] // you can set roles in root nav
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/brokerManage/index'));
      });
    },
    name: 'BrokerManageIndex',
    meta: {
      title: 'brokerManageIndex',
      icon: 'broker',
      roles: ['broker']
    }
  }, {
    path: 'anchor',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/brokerManage/anchor'));
      });
    },
    name: 'BrokerManageAnchor',
    meta: {
      title: 'brokerManageAnchor',
      icon: 'broker',
      roles: ['broker']
    }
  }, {
    path: 'income',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/brokerManage/income'));
      });
    },
    name: 'BrokerManageIncome',
    meta: {
      title: 'brokerManageIncome',
      icon: 'broker',
      roles: ['broker']
    }
  }, {
    path: 'settlement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/brokerManage/settlement'));
      });
    },
    name: 'BrokerSettlement',
    meta: {
      title: 'brokerSettlement',
      icon: 'settlement',
      roles: ['broker']
    }
  }]
},
// 分销权限
{
  path: '/distributors',
  component: _layout.default,
  redirect: '/distributors/index',
  name: 'DistributorsManage',
  meta: {
    title: 'distributorsReport',
    icon: 'broker',
    roles: ['distributors'] // you can set roles in root nav
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/distributors/index'));
      });
    },
    name: '分销参数',
    meta: {
      title: '分销参数',
      icon: 'list',
      roles: ['distributors']
    }
  }, {
    path: 'delay',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/distributors/delayReport'));
      });
    },
    name: 'delayReport',
    meta: {
      title: 'distributorsReport',
      icon: 'list',
      roles: ['distributors']
    }
  }]
},
// 渠道权限
{
  path: '/channel',
  component: _layout.default,
  redirect: '/channel/index',
  name: 'ChannelManage',
  meta: {
    title: 'channelManage',
    icon: 'broker',
    roles: ['channel'] // you can set roles in root nav
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/channel/index'));
      });
    },
    name: 'ChannelManageData',
    meta: {
      title: 'channelManageData',
      icon: 'list',
      roles: ['channel']
    }
  }, {
    path: 'channel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/statistics/channel'));
      });
    },
    name: 'StatisticsChannel',
    meta: {
      title: 'channelHistory',
      icon: 'list',
      roles: ['channel']
    }
  }, {
    path: 'region',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/statistics/components/brace/region'));
      });
    },
    name: 'StatisticsComponentsRegion',
    meta: {
      title: 'countryData',
      icon: 'list',
      roles: ['channel']
    }
  }, {
    path: 'retainedUserReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/userReport/retainedUserReport'));
      });
    },
    name: 'retainedUserReport',
    meta: {
      title: 'retainedUserReport',
      icon: 'list',
      roles: ['channel']
    }
  }]
},
// 权限管理
{
  path: '/system',
  component: _layout.default,
  redirect: '/system/version',
  name: 'System',
  meta: {
    title: 'system',
    icon: 'permission',
    roles: ['admin', 'superAdmin'] // you can set roles in root nav
  },
  children: [{
    path: 'account',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/account'));
      });
    },
    name: 'SystemAccount',
    meta: {
      title: 'systemAccount',
      icon: 'user',
      roles: ['superAdmin']
    }
  }, {
    path: 'logs',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/logs'));
      });
    },
    name: 'SystemLogs',
    meta: {
      title: 'systemLogs',
      icon: 'log',
      roles: ['admin', 'superAdmin']
    }
  }, {
    path: 'slowSqlLogs',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/slowSqlLogs'));
      });
    },
    name: 'SystemSlowSqlLogs',
    meta: {
      title: 'systemSlowSqlLogs',
      icon: 'log',
      roles: ['admin', 'superAdmin']
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;