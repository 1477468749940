"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_vm.roles.includes("channel") ? _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      filterable: "",
      placeholder: "应用",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.app_name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "app_name", $$v);
      },
      expression: "listQuery.app_name"
    }
  }, [_c("el-option", {
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.appNames, function (item) {
    return _c("el-option", {
      key: item.app_name,
      attrs: {
        label: item.app_name,
        value: item.app_name
      }
    });
  })], 2) : _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      filterable: "",
      placeholder: "应用",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.app_name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "app_name", $$v);
      },
      expression: "listQuery.app_name"
    }
  }, [_c("el-option", {
    attrs: {
      label: "全部",
      value: ""
    }
  }), _vm._v(" "), _vm._l(_vm.brandList, function (item) {
    return _c("el-option", {
      key: item.app_name,
      attrs: {
        label: item.app_name,
        value: item.app_name
      }
    });
  })], 2), _vm._v(" "), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "130px"
    },
    attrs: {
      filterable: "",
      placeholder: "类型",
      clearable: ""
    },
    model: {
      value: _vm.listQuery.type,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "type", $$v);
      },
      expression: "listQuery.type"
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _vm._v(" "), _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      "value-format": "yyyy-MM-dd",
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": _vm.$t("form.startDate"),
      "end-placeholder": _vm.$t("form.endDate")
    },
    model: {
      value: _vm.times,
      callback: function callback($$v) {
        _vm.times = $$v;
      },
      expression: "times"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(_vm._s(_vm.$t("table.search")))])], 1), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      fixed: "",
      align: "center",
      prop: "report_date",
      label: "日期"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeFilter")(row.report_date, "yyyy-MM-dd")) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "",
      align: "center",
      prop: "new_users",
      label: "新增用户"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay0",
      label: "当日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay1",
      label: "1日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay2",
      label: "2日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay3",
      label: "3日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay4",
      label: "4日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay5",
      label: "5日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay6",
      label: "6日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay7",
      label: "7日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay8",
      label: "8日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay9",
      label: "9日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay10",
      label: "10日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay11",
      label: "11日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay12",
      label: "12日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay13",
      label: "13日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay14",
      label: "14日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay15",
      label: "15日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay16",
      label: "16日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay17",
      label: "17日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay18",
      label: "18日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay19",
      label: "19日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay20",
      label: "20日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay21",
      label: "21日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay22",
      label: "22日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay23",
      label: "23日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay24",
      label: "24日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay25",
      label: "25日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay26",
      label: "26日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay27",
      label: "27日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay28",
      label: "28日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay29",
      label: "29日"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "retainedDay30",
      label: "30日"
    }
  })], 1), _vm._v(" "), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total>0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.size,
      "page-sizes": [30, 50, 100, 500]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "size", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;