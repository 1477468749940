"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _validate = require("@/utils/validate");
var _LangSelect = _interopRequireDefault(require("@/components/LangSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  components: {
    LangSelect: _LangSelect.default
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('Please enter the correct user name'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: '',
        googleCode: '',
        account: 'admin'
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      dialogVisible: false,
      googleCodeError: ''
    };
  },
  computed: {
    isShowGoogleCode: function isShowGoogleCode() {
      var url = window.location.host;
      return !(url === process.env.VUE_APP_URL_BROKER_HOST || url === process.env.VUE_APP_URL_CHANNEL_HOST);
    }
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  methods: {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    // 判断账号权限
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          var url = window.location.host;
          if (url === process.env.VUE_APP_URL_ADMIN_HOST) {
            _this2.loginForm.account = 'admin';
          } else if (url === process.env.VUE_APP_URL_BROKER_HOST) {
            _this2.loginForm.account = 'broker';
          } else if (url === process.env.VUE_APP_URL_CHANNEL_HOST) {
            _this2.loginForm.account = 'channel';
          }
          // this.loginForm.account = 'channel' // 测试打开渠道后台用
          if (_this2.dialogVisible && _this2.loginForm.googleCode === '') {
            _this2.googleCodeError = 'Please enter Google verification code';
            return false;
          }
          _this2.loading = true;
          // this.$store.dispatch('user/login', this.loginForm) 来调取store里的user.js的login方法，从而要更新。
          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            // 登录成功之后重定向到首页
            _this2.$router.push({
              path: _this2.redirect || '/',
              query: _this2.otherQuery
            });
            _this2.loading = false;
          }).catch(function (_ref) {
            var code = _ref.code;
            _this2.loading = false;
            if (code === 2) {
              // 显示输入谷歌验证码
              _this2.dialogVisible = true;
              _this2.$nextTick(function () {
                _this2.$refs.googleCode.focus();
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 取消输入谷歌验证码
    handleLoginCancel: function handleLoginCancel() {
      this.loginForm.googleCode = '';
      this.dialogVisible = false;
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};