"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("./video-viewer.css");
var context = '@@videoViewerContext';
function handleClick(el, binding) {
  function handle(e) {
    // 关闭容器
    function close() {
      document.body.removeChild(containerEl);
    }
    // 设置当前视频查看状态
    var video_arr = JSON.parse(localStorage.getItem('video_arr'));
    if (video_arr) {
      video_arr.forEach(function (item) {
        if (item.key === binding.value) {
          item.video_see = true;
        }
      });
    }
    localStorage.setItem('video_arr', JSON.stringify(video_arr));
    // 创建容器
    var containerEl = document.createElement('div');
    containerEl.className = 'video-viewer__wrapper';
    containerEl.style.width = document.body.clientWidth + 'px';
    containerEl.style.height = document.body.clientHeight + 'px';

    // 创建加载中
    var loadingEl = document.createElement('p');
    loadingEl.innerHTML = '加载中...';
    loadingEl.className = 'video-viewer__loading';
    loadingEl.style.lineHeight = document.body.clientHeight + 'px';
    containerEl.appendChild(loadingEl);

    // 创建视频容器
    var videoEl = document.createElement('video');
    videoEl.src = binding.value;
    videoEl.className = 'video-viewer__video';
    videoEl.controls = 'controls';
    videoEl.autoplay = 'autoplay';
    videoEl.addEventListener('loadedmetadata', function () {
      containerEl.removeChild(loadingEl);
      containerEl.appendChild(videoEl);
    });

    // 创建关闭按钮
    var closeEl = document.createElement('span');
    closeEl.className = 'video-viewer__close';
    var iEl = document.createElement('i');
    iEl.className = 'el-icon-circle-close';
    closeEl.appendChild(iEl);
    closeEl.addEventListener('click', close, false);
    containerEl.appendChild(closeEl);

    // 将元素插入页面
    document.body.appendChild(containerEl);
  }
  if (!el[context]) {
    el[context] = {
      removeHandle: handle
    };
  } else {
    el[context].removeHandle = handle;
  }
  return handle;
}
var _default = exports.default = {
  bind: function bind(el, binding) {
    el.addEventListener('click', handleClick(el, binding), false);
  },
  update: function update(el, binding) {
    el.removeEventListener('click', el[context].removeHandle, false);
    el.addEventListener('click', handleClick(el, binding), false);
  },
  unbind: function unbind(el) {
    el.removeEventListener('click', el[context].removeHandle, false);
    el[context] = null;
    delete el[context];
    var node = document.querySelector('.video-viewer__wrapper');
    if (node) document.body.removeChild(document.querySelector('.video-viewer__wrapper'));
  }
};