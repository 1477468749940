"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      prop: "distributor_no",
      label: "分销编号"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "link",
      label: "分销链接(有点击)"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "center",
      prop: "noLink",
      label: "分销链接(无点击)"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;