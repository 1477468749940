"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _allAnchor = _interopRequireDefault(require("./components/allAnchor"));
var _recommend = _interopRequireDefault(require("./components/recommend"));
var _notRecommend = _interopRequireDefault(require("./components/notRecommend"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    AllAnchor: _allAnchor.default,
    Recommend: _recommend.default,
    NotRecommend: _notRecommend.default
  },
  data: function data() {
    return {
      activeName: 'allAnchor'
    };
  }
};